import { CadGeral } from './CadGeral';
import { Grid } from './Grid';
import { GridPerguntas } from './GridPerguntas';

export const Cadastro = (props) => {
  return (
    <>
      <div className="nav nav-tabs">
        <p
          className={`nav-link ${
            props.activeTabCadastro === 1 ? 'text-primary active' : 'text-dark'
          }`}
          onClick={() => props.setActiveTabCadastro(1)}
          style={{ fontSize: props.tamTexto }}
        >
          Dados Subarea
        </p>
        {props.insertOrUpdate !== 'insert' && (
          <>
            <p
              className={`nav-link ${
                props.activeTabCadastro === 2
                  ? 'text-primary active'
                  : 'text-dark'
              }`}
              onClick={() => props.setActiveTabCadastro(2)}
              style={{ fontSize: props.tamTexto }}
            >
              Perguntas da Subarea
            </p>
            <p
              className={`nav-link ${
                props.activeTabCadastro === 3
                  ? 'text-primary active'
                  : 'text-dark'
              }`}
              onClick={() => props.setActiveTabCadastro(3)}
              style={{ fontSize: props.tamTexto }}
            >
              Adicionar Perguntas da Subarea
            </p>
          </>
        )}
      </div>

      <div className="tab-content">
        <div
          className={`tab-pane ${
            props.activeTabCadastro === 1 ? 'active' : ''
          }`}
          id="tabCadastro1"
        >
          <CadGeral
            data={props.data}
            setData={props.setData}
            insertOrUpdate={props.insertOrUpdate}
            optionsArea={props.optionsArea}
          />
        </div>
        {props.insertOrUpdate !== 'insert' && (
          <>
            <div
              className={`tab-pane ${
                props.activeTabCadastro === 2 ? 'active' : ''
              }`}
              id="tabCadastro2"
            >
              <Grid
                data={props.data}
                setData={props.setData}
                insertOrUpdate={props.insertOrUpdate}
                optionsArea={props.optionsArea}
                optionsSubarea={props.optionsSubarea}
                optionsPergunta={props.optionsPergunta}
                filteredData={props.filteredDataPerguntaSubarea}
                selectedRow={props.selectedRowPerguntaSubarea}
                setSelectedRow={props.setSelectedRowPerguntaSubarea}
                currentPage={props.currentPagePerguntaSubarea}
                setCurrentPage={props.setCurrentPagePerguntaSubarea}
                pageCount={props.pageCountPerguntaSubarea}
                displayedPageNumbers={props.displayedPageNumbersPerguntaSubarea}
                dataGrid={props.optionsPerguntaSubarea}
                tamTexto={props.tamTexto}
                tamSubTexto={props.tamSubTexto}
                handleSort={props.handleSortPerguntaSubarea}
              />
            </div>
            <div
              className={`tab-pane ${
                props.activeTabCadastro === 3 ? 'active' : ''
              }`}
              id="tabCadastro3"
            >
              <GridPerguntas
                data={props.data}
                setData={props.setData}
                insertOrUpdate={props.insertOrUpdate}
                optionsArea={props.optionsArea}
                optionsSubarea={props.optionsSubarea}
                optionsPergunta={props.optionsPergunta}
                filteredData={props.filteredData}
                selectedRow={props.selectedRow}
                setSelectedRow={props.setSelectedRow}
                currentPage={props.currentPage}
                setCurrentPage={props.setCurrentPage}
                pageCount={props.pageCount}
                displayedPageNumbers={props.displayedPageNumbers}
                dataGrid={props.dataGrid}
                tamTexto={props.tamTexto}
                tamSubTexto={props.tamSubTexto}
                handleSort={props.handleSort}
                optionsPerguntaSubarea={props.optionsPerguntaSubarea}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
