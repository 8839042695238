import { useEffect, useState } from 'react';

import {
  areaSelect,
  getDisplayedPageNumbers,
  perguntaSelect,
  perguntaSubareaPost,
  perguntaSubareaSelect,
  subareaPost,
  subareaSelect,
  subareaUpdate
} from './functions';
import { Cadastro } from './subarea/cadastro/Cadastro';
import { Grid } from './subarea/Grid';

export const Subarea = (props) => {
  const [activeTab, setActiveTab] = useState(1);
  const [dataGrid, setDataGrid] = useState([]);
  const [insertOrUpdate, setInsertOrUpdate] = useState(null);
  const [data, setData] = useState({
    usuario: localStorage.getItem('sessionUsuario')
  });
  const [optionsArea, setOptionsArea] = useState([]);
  const [optionsPerguntaSubarea, setOptionsPerguntaSubarea] = useState([]);
  const [optionsPergunta, setOptionsPergunta] = useState([]);

  const [activeTabCadastro, setActiveTabCadastro] = useState(1);

  const itemsPerPage = 10;

  const [pageCountPergunta, setPageCountPergunta] = useState(0);
  const [currentPagePergunta, setCurrentPagePergunta] = useState(0);
  const [selectedRowPergunta, setSelectedRowPergunta] = useState(null);
  const [filteredDataPergunta, setFilteredDataPergunta] = useState([]);
  const offsetPergunta = currentPagePergunta * itemsPerPage;

  const [pageCountPerguntaSubarea, setPageCountPerguntaSubarea] = useState(0);
  const [currentPagePerguntaSubarea, setCurrentPagePerguntaSubarea] =
    useState(0);
  const [selectedRowPerguntaSubarea, setSelectedRowPerguntaSubarea] =
    useState(null);
  const [filteredDataPerguntaSubarea, setFilteredDataPerguntaSubarea] =
    useState([]);
  const offsetPerguntaSubarea = currentPagePerguntaSubarea * itemsPerPage;

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const offset = currentPage * itemsPerPage;

  const displayedPageNumbers = getDisplayedPageNumbers(currentPage, pageCount);
  const displayedPageNumbersPergunta = getDisplayedPageNumbers(
    currentPagePergunta,
    pageCountPergunta
  );
  const displayedPageNumbersPerguntaSubarea = getDisplayedPageNumbers(
    currentPagePerguntaSubarea,
    pageCountPerguntaSubarea
  );

  const handleSearch = async (dataFiltro) => {
    setDataGrid(await subareaSelect(dataFiltro));
    setOptionsArea(await areaSelect());
    setOptionsPergunta(await perguntaSelect({}));
    setOptionsPerguntaSubarea(await perguntaSubareaSelect({}));
  };

  const handleSort = (order, direction) => {
    let multiplier = direction === 'asc' ? 1 : -1;
    switch (order) {
      case 'sar_codare':
        setDataGrid(
          [...dataGrid].sort(
            (a, b) => multiplier * a.sar_codare.localeCompare(b.sar_codare)
          )
        );
        break;
      case 'sar_codsar':
        setDataGrid(
          [...dataGrid].sort(
            (a, b) => multiplier * a.sar_codsar.localeCompare(b.sar_codsar)
          )
        );
        break;
      case 'sar_descri':
        setDataGrid(
          [...dataGrid].sort(
            (a, b) => multiplier * a.sar_descri.localeCompare(b.sar_descri)
          )
        );
        break;
      case 'sar_abrev':
        setDataGrid(
          [...dataGrid].sort(
            (a, b) => multiplier * a.sar_abrev.localeCompare(b.sar_abrev)
          )
        );
        break;
      case 'sar_pesosar':
        setDataGrid(
          [...dataGrid].sort(
            (a, b) =>
              multiplier *
              String(a.sar_pesosar)?.localeCompare(String(b.sar_pesosar))
          )
        );
        break;
      default:
        setDataGrid([...dataGrid]);
        break;
    }
  };

  const handleSortPergunta = (order, direction) => {
    let multiplier = direction === 'asc' ? 1 : -1;
    switch (order) {
      case 'id':
        setOptionsPergunta(
          [...optionsPergunta].sort(
            (a, b) => multiplier * a.id.localeCompare(b.id)
          )
        );
        break;
      case 'descricao':
        setOptionsPergunta(
          [...optionsPergunta].sort(
            (a, b) => multiplier * a.descricao.localeCompare(b.descricao)
          )
        );
        break;
      case 'abreviacao':
        setOptionsPergunta(
          [...optionsPergunta].sort(
            (a, b) => multiplier * a.abreviacao.localeCompare(b.abreviacao)
          )
        );
        break;
      default:
        setOptionsPergunta([...optionsPergunta]);
        break;
    }
  };

  const handleSortPerguntaSubarea = (order, direction) => {
    let multiplier = direction === 'asc' ? 1 : -1;
    switch (order) {
      case 'codigoArea':
        setOptionsPerguntaSubarea(
          [...optionsPerguntaSubarea].sort(
            (a, b) => multiplier * a.codigoArea.localeCompare(b.codigoArea)
          )
        );
        break;
      case 'descricaoArea':
        setOptionsPerguntaSubarea(
          [...optionsPerguntaSubarea].sort(
            (a, b) =>
              multiplier * a.descricaoArea.localeCompare(b.descricaoArea)
          )
        );
        break;
      case 'codigoSubarea':
        setOptionsPerguntaSubarea(
          [...optionsPerguntaSubarea].sort(
            (a, b) =>
              multiplier * a.codigoSubarea.localeCompare(b.codigoSubarea)
          )
        );
        break;
      case 'descricaoSubarea':
        setOptionsPerguntaSubarea(
          [...optionsPerguntaSubarea].sort(
            (a, b) =>
              multiplier * a.descricaoSubarea.localeCompare(b.descricaoSubarea)
          )
        );
        break;
      case 'codigoPergunta':
        setOptionsPerguntaSubarea(
          [...optionsPerguntaSubarea].sort(
            (a, b) =>
              multiplier * a.codigoPergunta.localeCompare(b.codigoPergunta)
          )
        );
        break;
      case 'descricaoPergunta':
        setOptionsPerguntaSubarea(
          [...optionsPerguntaSubarea].sort(
            (a, b) =>
              multiplier *
              a.descricaoPergunta.localeCompare(b.descricaoPergunta)
          )
        );
        break;
      default:
        setOptionsPerguntaSubarea([...optionsPerguntaSubarea]);
        break;
    }
  };

  useEffect(() => {
    if (props.activeTab === 2) {
      handleSearch(props.dataFiltro);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeTab, props.filter]);

  useEffect(() => {
    if (dataGrid != undefined) {
      setFilteredData(dataGrid.slice(offset, offset + itemsPerPage));
      setPageCount(Math.ceil(dataGrid.length / itemsPerPage));
      setCurrentPage(0);
      setSelectedRow(null);
    }
    // eslint-disable-next-line
  }, [dataGrid]);

  useEffect(() => {
    if (dataGrid !== undefined) {
      setFilteredData(dataGrid.slice(offset, offset + itemsPerPage));
    }
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    if (optionsPergunta != undefined && activeTab === 2) {
      setFilteredDataPergunta(() => {
        const filter = optionsPergunta.filter(
          (item) =>
            !optionsPerguntaSubarea.some(
              (option) =>
                option.codigoArea === data.codigoArea &&
                option.codigoSubarea === data.id &&
                item.id === option.codigoPergunta
            )
        );

        return filter.slice(offsetPergunta, offsetPergunta + itemsPerPage);
      });
      setPageCountPergunta(
        Math.ceil(
          optionsPergunta.filter(
            (item) =>
              !optionsPerguntaSubarea.some(
                (option) =>
                  option.codigoArea === data.codigoArea &&
                  option.codigoSubarea === data.id &&
                  item.id === option.codigoPergunta
              )
          ).length / itemsPerPage
        )
      );

      setCurrentPagePergunta(0);
      setSelectedRowPergunta(null);
    }
    // eslint-disable-next-line
  }, [optionsPergunta, activeTab]);

  useEffect(() => {
    if (optionsPergunta !== undefined && activeTab === 2) {
      setFilteredDataPergunta(() => {
        const filter = optionsPergunta.filter(
          (item) =>
            !optionsPerguntaSubarea.some(
              (option) =>
                option.codigoArea === data.codigoArea &&
                option.codigoSubarea === data.id &&
                item.id === option.codigoPergunta
            )
        );

        return filter.slice(offsetPergunta, offsetPergunta + itemsPerPage);
      });
    }
    // eslint-disable-next-line
  }, [currentPagePergunta, activeTab]);

  useEffect(() => {
    if (optionsPerguntaSubarea != undefined && activeTab === 2) {
      setFilteredDataPerguntaSubarea(() => {
        const filter = optionsPerguntaSubarea.filter(
          (item) =>
            item.codigoArea === data.codigoArea &&
            item.codigoSubarea === data.id
        );

        return filter.slice(
          offsetPerguntaSubarea,
          offsetPerguntaSubarea + itemsPerPage
        );
      });
      setPageCountPerguntaSubarea(
        Math.ceil(
          optionsPerguntaSubarea.filter(
            (item) =>
              item.codigoArea === data.codigoArea &&
              item.codigoSubarea === data.id
          ).length / itemsPerPage
        )
      );
      setCurrentPagePerguntaSubarea(0);
      setSelectedRowPerguntaSubarea(null);
    }
    // eslint-disable-next-line
  }, [optionsPerguntaSubarea, activeTab]);

  useEffect(() => {
    if (optionsPerguntaSubarea !== undefined && activeTab === 2) {
      setFilteredDataPerguntaSubarea(() => {
        const filter = optionsPerguntaSubarea.filter(
          (item) =>
            item.codigoArea === data.codigoArea &&
            item.codigoSubarea === data.id
        );

        return filter.slice(
          offsetPerguntaSubarea,
          offsetPerguntaSubarea + itemsPerPage
        );
      });
    }
    // eslint-disable-next-line
  }, [currentPagePerguntaSubarea, activeTab]);

  useEffect(() => {
    if (props.tabInsert === 2) {
      const data = new Date();
      const ano = data.getFullYear();
      const mes = String(data.getMonth() + 1).padStart(2, '0');
      const dia = String(data.getDate()).padStart(2, '0');

      const hora = data.getHours();
      const minuto = String(data.getMinutes()).padStart(2, '0');
      const segundo = String(data.getSeconds()).padStart(2, '0');

      setData({
        ...data,
        usuario: localStorage.getItem('sessionUsuario'),
        datincl: `${ano}-${mes}-${dia}`,
        datatz: `${ano}-${mes}-${dia}`,
        horatz: `${hora}:${minuto}:${segundo}`,
        horincl: `${hora}:${minuto}:${segundo}`,
        empresa: localStorage.getItem('sessionEmpresa'),
        loja: localStorage.getItem('sessionLoja')
      });
      setInsertOrUpdate('insert');
      props.setNvAcesso('insert');
      setActiveTab(2);
      props.setTabReturn(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabInsert]);

  useEffect(() => {
    if (props.tabReturn === 0) {
      setActiveTab(1);
      setData({ usuario: localStorage.getItem('sessionUsuario') });
    }
  }, [props.tabReturn]);

  useEffect(() => {
    if (props.tabUpdate === 2) {
      const fetch = async () => {
        if (insertOrUpdate === 'insert') await subareaPost(data);
        else {
          if (activeTabCadastro === 1) {
            await subareaUpdate(data);
          } else if (activeTabCadastro === 3) {
            let response = false;

            for (const pergunta of data.perguntas) {
              const body = {
                id: data.codigoArea,
                subarea: data.id,
                pergunta: pergunta
              };

              response = await perguntaSubareaPost(body);
            }

            if (response) alert('Resgitro gravado com sucesso');
          }
        }

        setData({
          usuario: localStorage.getItem('sessionUsuario')
        });

        await handleSearch();

        setActiveTab(1);
        setActiveTabCadastro(1);
        props.setTabReturn(0);
        props.setTabUpdate(0);
      };
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabUpdate]);

  return (
    <div>
      <div>
        <div className="nav nav-tabs">
          <p
            className={`nav-link ${
              activeTab === 1 ? 'text-primary active' : 'text-dark'
            }`}
            onClick={() => setActiveTab(1)}
            style={{ display: 'none' }}
          >
            {/* Tabela */}
          </p>
          <p
            className={`nav-link ${
              activeTab === 2 ? 'text-primary active' : 'text-dark'
            }`}
            onClick={() => setActiveTab(2)}
            style={{ display: 'none' }}
          >
            {/* Insert */}
          </p>
        </div>

        <div className="tab-content">
          <div
            className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
            id="tab1"
          >
            <Grid
              filteredData={filteredData}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageCount={pageCount}
              displayedPageNumbers={displayedPageNumbers}
              setActiveTab={setActiveTab}
              data={data}
              setData={setData}
              dataGrid={dataGrid}
              setInsertOrUpdate={setInsertOrUpdate}
              handleSearch={handleSearch}
              tamTexto={props.tamTexto}
              tamSubTexto={props.tamSubTexto}
              setTabReturn={props.setTabReturn}
              setNvAcesso={props.setNvAcesso}
              optionsArea={optionsArea}
              handleSort={handleSort}
            />
          </div>
          <div
            className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
            id="tab2"
          >
            <Cadastro
              data={data}
              setData={setData}
              insertOrUpdate={insertOrUpdate}
              optionsArea={optionsArea}
              optionsPergunta={optionsPergunta}
              filteredData={filteredDataPergunta}
              filteredDataPerguntaSubarea={filteredDataPerguntaSubarea}
              selectedRow={selectedRowPergunta}
              setSelectedRow={setSelectedRowPergunta}
              selectedRowPerguntaSubarea={selectedRowPerguntaSubarea}
              setSelectedRowPerguntaSubarea={setSelectedRowPerguntaSubarea}
              currentPage={currentPagePergunta}
              setCurrentPage={setCurrentPagePergunta}
              pageCount={pageCountPergunta}
              displayedPageNumbers={displayedPageNumbersPergunta}
              currentPagePerguntaSubarea={currentPagePerguntaSubarea}
              setCurrentPagePerguntaSubarea={setCurrentPagePerguntaSubarea}
              pageCountPerguntaSubarea={pageCountPerguntaSubarea}
              displayedPageNumbersPerguntaSubarea={
                displayedPageNumbersPerguntaSubarea
              }
              dataGrid={optionsPergunta}
              tamTexto={props.tamTexto}
              tamSubTexto={props.tamSubTexto}
              handleSort={handleSortPergunta}
              handleSortPerguntaSubarea={handleSortPerguntaSubarea}
              optionsPerguntaSubarea={optionsPerguntaSubarea}
              activeTabCadastro={activeTabCadastro}
              setActiveTabCadastro={setActiveTabCadastro}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
