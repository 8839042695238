import { useState } from 'react';

import { ApiGet, ApiPost } from '../../services/apiService';
import { Cadastro } from './cadastro/Cadastro';
import { CtrNavegacao } from './navegacao/CtrNavegacao';

import Swal from 'sweetalert2';

export const Auditoria = (props) => {
  const [activeTab, setActiveTab] = useState(1);
  const [filtroPesquisa, setFiltroPesquisa] = useState([]);
  const [contRegistros, setContRegistros] = useState(0);
  const [formData, setFormData] = useState([]);
  const [data, setData] = useState([]);
  const [tela, setTela] = useState(1);
  const [cad, setCad] = useState(1);
  const [ambiente, setAmbiente] = useState('');
  const [dataInsert, setDataInsert] = useState([]);
  const [respostas, setRespostas] = useState([]);
  const [id, setId] = useState('');
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const handleRespostaClick = (index, resposta) => {
    const respostasAtualizadas = [...respostas];
    respostasAtualizadas[index] = resposta;
    setRespostas(respostasAtualizadas);
  };

  const handleSort = (order, direction) => {
    let multiplier = direction === 'asc' ? 1 : -1;
    switch (order) {
      case 'seq':
        setData(
          [...data].sort(
            (a, b) => multiplier * a.qap_seqque.localeCompare(b.qap_seqque)
          )
        );
        break;
      case 'ambiente':
        setData(
          [...data].sort(
            (a, b) => multiplier * a.que_descri.localeCompare(b.que_descri)
          )
        );
        break;
      default:
        setData([...data]);
        break;
    }
  };

  const onSave = async () => {
    Swal.fire({
      title: 'Atualizar',
      html: 'Confirmar as respostas?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: false
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          Swal.fire({
            title: 'Salvando...',
            html: 'Por favor, aguarde.',
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });

          setIsLoadingSave(true);
          const body = [];
          const currentCodUsu = localStorage
            .getItem('sessionUsuario')
            .toUpperCase();
          const now = new Date();
          const hours = String(now.getHours()).padStart(2, '0');
          const minutes = String(now.getMinutes()).padStart(2, '0');
          const seconds = String(now.getSeconds()).padStart(2, '0');
          const hora = `${hours}:${minutes}:${seconds}`;

          for (let i = 0; i < dataInsert.length; i++) {
            const resposta =
              respostas[i] === 'ACERTOU'
                ? 'S'
                : '' || respostas[i] === 'NÃO ACERTOU'
                ? 'N'
                : respostas[i];

            const dataItem = {
              ...dataInsert[i],
              resposta,
              codusu: currentCodUsu,
              datapl: now.toISOString().split('T')[0],
              datatz: now.toISOString().split('T')[0],
              horatz: hora,
              horapl: hora
            };
            body.push(dataItem);
          }

          const response = await ApiPost(
            `/auditoria/perguntaresposta`,
            body,
            localStorage.getItem('sessionToken')
          );
          if (response?.data?.mensagem?.msg.slice(0, 3) !== '100') {
            alert(response?.data?.mensagem?.msg);
            return;
          }

          Swal.fire({
            title: 'Respostas Confirmadas!!',
            icon: 'success',
            confirmButtonColor: '#d33',
            confirmButtonText: 'Fechar',
            allowOutsideClick: false
          });

          setTela(1);
          setCad(1);
          setRespostas([]);
        } catch (err) {
          console.log(err);
        } finally {
          handleEdit(ambiente, id);
          setIsLoadingSave(false);
        }
      }
    });
  };

  const handleEdit = async (amb, id) => {
    try {
      setIsLoadingEdit(true);
      const response = await ApiGet(
        `/auditoria/consulta/${id}`,
        {
          empresa: localStorage.getItem('sessionEmpresa'),
          loja: localStorage.getItem('sessionLoja'),
          unidadeAdm: localStorage.getItem('sessionAdm')
        },
        localStorage.getItem('sessionToken')
      );
      setId(id);
      setFormData(response.data.auditoria);
      setActiveTab(2);
      setTela(1);
      setAmbiente(amb);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingEdit(false);
    }
  };

  return (
    <div
      className="modal fade modal-xl"
      id="Menu-Auditoria"
      tabIndex="-1"
      aria-labelledby="lblAuditoria"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-scrollable modalDialog">
        <div className="modal-content" style={{ height: '100vh' }}>
          <div className="modal-header" style={{ height: '3rem' }}>
            <h1
              className="modal-title"
              id="lblAuditoria"
              style={{ fontSize: props.tamTitulo }}
            >
              Auditoria
            </h1>
            {activeTab === 1 && (
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            )}
          </div>

          <div className="modal-body">
            <div className="nav nav-tabs" style={{ marginTop: '-1rem' }}>
              <p style={{ display: 'none' }}>Navegação</p>
              <p style={{ display: 'none' }}>Cadastro</p>
            </div>

            <div className="tab-content">
              <div
                className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
                id="tab1"
              >
                <CtrNavegacao
                  setActiveTab={setActiveTab}
                  data={data}
                  setData={setData}
                  contRegistros={contRegistros}
                  setContRegistros={setContRegistros}
                  handleSort={handleSort}
                  login={props.login}
                  tamInput={props.tamInput}
                  tamTitulo={props.tamTitulo}
                  tamSubTitulo={props.tamSubTitulo}
                  tamTexto={props.tamTexto}
                  tamSubTexto={props.tamSubTexto}
                  token={localStorage.getItem('sessionToken')}
                  filtroPesquisa={filtroPesquisa}
                  setFiltroPesquisa={setFiltroPesquisa}
                  dataBanco={props.dataBanco}
                  setDataBanco={props.setDataBanco}
                  setFormData={setFormData}
                  setTela={setTela}
                  ambiente={ambiente}
                  setAmbiente={setAmbiente}
                  handleEdit={handleEdit}
                  isLoadingEdit={isLoadingEdit}
                />
              </div>
              <div
                className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
                id="tab2"
              >
                <Cadastro
                  formData={formData}
                  tela={tela}
                  setTela={setTela}
                  dataBanco={props.dataBanco}
                  token={localStorage.getItem('sessionToken')}
                  ambiente={ambiente}
                  id={id}
                  setAmbiente={setAmbiente}
                  cad={cad}
                  setCad={setCad}
                  dataInsert={dataInsert}
                  setDataInsert={setDataInsert}
                  handleRespostaClick={handleRespostaClick}
                  respostas={respostas}
                  setRespostas={setRespostas}
                  tamInput={props.tamInput}
                  tamTitulo={props.tamTitulo}
                  tamSubTitulo={props.tamSubTitulo}
                  tamTexto={props.tamTexto}
                  tamSubTexto={props.tamSubTexto}
                />
              </div>
            </div>
          </div>

          {activeTab === 2 ? (
            <div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    if (tela === 1) {
                      setActiveTab(1);
                      setRespostas([]);
                    }
                    if (tela === 2) {
                      setTela(1);
                      setCad(1);
                      setRespostas([]);
                    }
                  }}
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-right-from-bracket fa-rotate-180"></i>
                </button>
                {tela === 2 && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ fontSize: props.tamTexto }}
                    onClick={onSave}
                    disabled={isLoadingSave}
                  >
                    {isLoadingSave ? (
                      <i className="fa-solid fa-hourglass-start"></i>
                    ) : (
                      <i className="fa-solid fa-check"></i>
                    )}
                  </button>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
