export const Cadastro = (props) => {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Cód. Empresa</span>
          </div>
          <div className="col-9">
            <span className="col-form-label">Nome Empresa</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="empresa"
              value={props.data?.empresa || ''}
              onChange={props.handleInput}
              disabled
            />
          </div>
          <div className="col-9">
            <select
              className="form-select form-select-sm border border-dark"
              name="empresaNome"
              value={props.data?.empresaNome || ''}
              onChange={(e) => {
                const filter = props.options[1]?.filter(
                  (option) => option.emp_fantasia === e.target.value
                );

                props.setData({
                  ...props.data,
                  empresa: filter[0]?.emp_codemp,
                  empresaNome: e.target.value
                });
              }}
              disabled={props.insertOrUpdate === 'read' && true}
            >
              <option defaultValue></option>
              {props.options[1]?.map((option, index) => (
                <option key={index} value={option.emp_fantasia}>
                  {option.emp_fantasia}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Cód. Loja</span>
          </div>
          <div className="col-9">
            <span className="col-form-label">Nome Loja</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="loja"
              value={props.data?.loja || ''}
              onChange={props.handleInput}
              disabled
            />
          </div>
          <div className="col-9">
            <select
              className="form-select form-select-sm border border-dark"
              name="lojaNome"
              value={props.data?.lojaNome || ''}
              onChange={(e) => {
                const filter = props.options[2]?.filter(
                  (option) => option.loj_denloj === e.target.value
                );

                props.setData({
                  ...props.data,
                  loja: filter[0]?.loj_codloj,
                  lojaNome: e.target.value
                });
              }}
              disabled={props.insertOrUpdate === 'read' && true}
            >
              <option defaultValue></option>
              {props.options[2]?.map((option, index) => (
                <option key={index} value={option.loj_denloj}>
                  {option.loj_denloj}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Cód Und.</span>
          </div>
          <div className="col-9">
            <span className="col-form-label">Nome Und. Administrativa</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="unidade"
              value={props.data?.unidade || ''}
              onChange={props.handleInput}
              disabled
            />
          </div>
          <div className="col-9">
            <select
              className="form-select form-select-sm border border-dark"
              name="unidadeNome"
              value={props.data?.unidadeNome || ''}
              onChange={(e) => {
                const filter = props.options[3]?.filter(
                  (option) => option.nome_unidade_adm === e.target.value
                );

                props.setData({
                  ...props.data,
                  unidade: filter[0]?.codigo_unidade_adm,
                  unidadeNome: e.target.value
                });
              }}
              disabled={props.insertOrUpdate === 'read' && true}
            >
              <option defaultValue></option>
              {props.options[3]?.map((option, index) => (
                <option key={index} value={option.nome_unidade_adm}>
                  {option.nome_unidade_adm}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Cód. Ambiente</span>
          </div>
          <div className="col-9">
            <span className="col-form-label">Descrição do Ambiente</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={props.data?.codigo || ''}
              disabled
            />
          </div>
          <div className="col-9">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={props.data?.descricao || ''}
              onChange={(e) =>
                props.setData({ ...props.data, descricao: e.target.value })
              }
              disabled={props.insertOrUpdate === 'read' && true}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Status</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <select
              className="form-select form-select-sm border border-dark"
              value={props.data?.status || ''}
              onChange={(e) =>
                props.setData({ ...props.data, status: e.target.value })
              }
              disabled={props.insertOrUpdate === 'read' && true}
            >
              <option defaultValue></option>
              <option value="ATIVO">ATIVO</option>
              <option value="INATIVO">INATIVO</option>
            </select>
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Usuário</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={props.data?.usuario || ''}
              disabled
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Dt. Inclusão</span>
          </div>
          <div className="col-3">
            <span className="col-form-label">Hr. Inclusão</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              value={props.data?.dataInclusao || ''}
              disabled
            />
          </div>
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={props.data?.horaInclusao || ''}
              disabled
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Dt. Atualização</span>
          </div>
          <div className="col-3">
            <span className="col-form-label">Hr. Atualização</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              value={props.data?.dataAlteracao || ''}
              disabled
            />
          </div>
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={props.data?.horaAlteracao || ''}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};
