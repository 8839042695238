import { initReactI18next } from 'react-i18next';

import translationEN from '../../translation/en.json';
import translationES from '../../translation/es.json';
import translationPTBR from '../../translation/ptbr.json';

import i18n from 'i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationEN },
    ptBR: { translation: translationPTBR },
    es: { translation: translationES }
  },
  lng: 'ptBR',
  fallbackLng: 'ptBR',

  interpolation: {
    escapeValue: false
  }
});

export default i18n;
