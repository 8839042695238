import { menu_condicao } from '../services/menu-condicao';

export const handleCard = (area) => {
  const nivelAdm = String(localStorage.getItem('sessionNivelDeAdministracao'));

  if (area === 'COMER') {
    switchCardComer(nivelAdm);
  }

  return menu_condicao;
};

const updateSituacao = (keys, situacao) => {
  menu_condicao.forEach((item) => {
    const key = Object.keys(item)[0];
    if (keys.includes(key)) {
      item[key].situacao = situacao;
    }
  });
};

function switchCardComer(nivelAdm) {
  switch (nivelAdm) {
    case '05':
      updateSituacao(['auditoria'], 'ATIVO');
      break;

    case '08':
      updateSituacao(['auditoria'], 'ATIVO');
      break;

    case '10':
      updateSituacao(['auditoria'], 'ATIVO');
      break;

    case '12':
      updateSituacao(['auditoria'], 'ATIVO');
      break;

    case '14':
      updateSituacao(['auditoria'], 'ATIVO');
      break;

    case '16':
      updateSituacao(['auditoria'], 'ATIVO');
      break;

    case '20':
      updateSituacao(['auditoria'], 'ATIVO');
      break;

    case '50':
      updateSituacao(['auditoria', 'manutencao'], 'ATIVO');
      break;

    case '99':
      updateSituacao(['auditoria', 'manutencao'], 'ATIVO');
      break;
  }
}
