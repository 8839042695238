import React from 'react';

import { ResponsiveBar } from '@nivo/bar';

export const GraficoBarraSemData = ({
  dataForm,
  mesIni,
  anoIni,
  titulo,
  meta = 0
}) => {
  //   const placeholderData = [
  //     { id: 'id1', value: 10 },
  //     { id: 'id2', value: 7 },
  //     { id: 'id3', value: 8 }
  //   ];

  return (
    <div
      style={{
        height: '90%',
        width: '100%',
        minWidth: '600px',
        minHeight: '400px'
      }}
    >
      <h3 style={{ textAlign: 'center', color: '#000' }}>{titulo}</h3>
      <h4 style={{ textAlign: 'center', color: '#000' }}>
        Período {mesIni}/{anoIni}
      </h4>
      <ResponsiveBar
        data={dataForm}
        keys={['value']}
        indexBy="id"
        groupMode="grouped"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={({ data }) => (data.value < meta ? '#ff4d4d' : '#4d4dff')}
        borderColor={{ from: 'color', modifiers: [['darker', 1.8]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 45, // Rotate 45 degrees
          legendPosition: 'middle',
          legendOffset: 32
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Nota',
          legendPosition: 'middle',
          legendOffset: -40
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        valueFormat=" >-.3s"
        tooltip={({ indexValue, value, color }) => (
          <strong
            style={{
              background: 'white',
              padding: '9px 12px',
              border: '1px solid #ccc'
            }}
          >
            {indexValue}: {value.toFixed(2)}
          </strong>
        )}
        animate={false}
        motionStiffness={90}
        motionDamping={15}
      />
    </div>
  );
};
