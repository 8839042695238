import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

export const ChartLine = ({ data, seq, mesIni, anoIni, mesFim, anoFim }) => {
  if (!data || data.length !== 2) {
    return <p>Não existe data suficiente.</p>;
  }

  let [dadosGrafico, mediaArray] = data;

  if (!dadosGrafico || dadosGrafico.length === 0) {
    return <p>Não existe data suficiente.</p>;
  }
  const descricao = dadosGrafico[0].que_descri;
  const formatDate = (dateString) => {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const options = { year: 'numeric', month: 'short' };
    return new Date(year, month - 1).toLocaleDateString('pt-br', options);
  };

  const findMedia = (anomes) => {
    const mediaObj = dadosGrafico.find((item) => item.anomes === anomes);
    return mediaObj ? mediaObj.media_quest_mes : null;
  };

  const labels = mediaArray.map((item) => formatDate(item.anomes));
  const metaValue = parseInt(localStorage.getItem('sessionMeta'));

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: descricao,
        data: mediaArray.map((item) => findMedia(item.anomes)),
        borderColor: '#cc9900',
        backgroundColor: '#cc9900',
        fill: false,
        tension: 0.0
      },
      {
        label: 'Media da Empresa',
        data: mediaArray.map((item) => item.media_empresa_dia),
        borderColor: '#ffcc00',
        backgroundColor: '#ffcc00',
        fill: false,
        tension: 0.0
      },
      {
        label: 'Meta',
        data: mediaArray.map(() => metaValue),
        borderColor: 'green',
        backgroundColor: 'green',
        fill: false,
        tension: 0.0
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle'
        }
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: ${context.raw.toFixed(2)}`;
          }
        }
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Data da Aplicacao'
        }
      },
      y: {
        title: {
          display: true,
          text: 'Nota do Ambiente'
        },
        ticks: {
          callback: function (value) {
            return parseFloat(value).toLocaleString('pt-BR', {
              style: 'decimal',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            });
          }
        }
      }
    }
  };

  return (
    <div
      style={{
        height: '90%',
        width: '100%',
        minWidth: '600px',
        minHeight: '400px'
      }}
    >
      <h3 style={{ textAlign: 'center', color: '#000' }}>
        Nota do Ambiente/Media da Empresa
      </h3>
      <h4 style={{ textAlign: 'center', color: '#000' }}>
        Ambiente: {descricao} | Período {mesIni}/{anoIni} - {mesFim}/{anoFim}
      </h4>
      <Line data={chartData} options={options} />
    </div>
  );
};
