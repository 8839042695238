export const Cadastro = ({ data, setData, optionsUndadm, insertOrUpdate }) => {
  return (
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-12 row">
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Cód. da Resposta</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.id || ''}
            disabled
          />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-4">
          <label className="form-label">Descricão da Resposta</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.descricao || ''}
            onChange={(e) => setData({ ...data, descricao: e.target.value })}
            disabled={insertOrUpdate === 'read' && true}
          />
        </div>
        <div className="col-sm-12 col-md-4 col-lg-2">
          <label className="form-label">Máscara</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.mascara || ''}
            onChange={(e) => setData({ ...data, mascara: e.target.value })}
            disabled={insertOrUpdate === 'read' && true}
          />
        </div>
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Tipo Resposta</label>
          <select
            className="form-select form-select-sm border border-dark"
            value={data?.tipoResposta || ''}
            onChange={(e) => setData({ ...data, tipoResposta: e.target.value })}
            disabled={insertOrUpdate === 'read' && true}
          >
            <option defaultValue></option>
            <option value="OBJET">OBJET</option>
            <option value="SUBJE">SUBJE</option>
          </select>
        </div>
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Tipo Resposta Sub</label>
          <select
            className="form-select form-select-sm border border-dark"
            value={data?.tipoRespostaSub || ''}
            onChange={(e) =>
              setData({ ...data, tipoRespostaSub: e.target.value })
            }
            disabled={insertOrUpdate === 'read' && true}
          >
            <option defaultValue></option>
            <option value="RSBNU">RSBNU</option>
            <option value="RSBST">RSBST</option>
          </select>
        </div>
      </div>

      <div className="col-sm-12 col-md-12 col-lg-12 row">
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Usuário</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.usuario || ''}
            disabled
          />
        </div>
      </div>

      <div className="col-sm-12 col-md-12 col-lg-12 row">
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Dt. Inclusão</label>
          <input
            type="date"
            className="form-control form-control-sm border border-dark"
            value={data?.dataInclusao || ''}
            disabled
          />
        </div>
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Hr. Inclusão</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.horaInclusao || ''}
            disabled
          />
        </div>
      </div>

      <div className="col-sm-12 col-md-12 col-lg-12 row">
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Dt. Atualização</label>
          <input
            type="date"
            className="form-control form-control-sm border border-dark"
            value={data?.dataAtualizacao || ''}
            disabled
          />
        </div>
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Hr. Atualização</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.horaAtualizacao || ''}
            disabled
          />
        </div>
      </div>
    </div>
  );
};
