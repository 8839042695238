import { useEffect, useState } from 'react';

import { ApiGetConsulta } from '../../services/apiService';
import { Ambiente } from './Ambiente';
import { SearchAmbiente } from './ambiente/SearchAmbiente';
import { Area } from './Area';
import { areaSelect, perguntaSelect, subareaSelect } from './functions';
import { Perguntas } from './Perguntas';
import { SearchPerguntas } from './perguntas/SearchPerguntas';
import { Respostas } from './Respostas';
import { SearchRespostas } from './respostas/SearchRespostas';
import { Subarea } from './Subarea';
import { SearchSubarea } from './subarea/SearchSubarea';

export const Manutencao = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [tabInsert, setTabInsert] = useState(0);
  const [tabUpdate, setTabUpdate] = useState(0);
  const [tabReturn, setTabReturn] = useState(0);
  const [nvAcesso, setNvAcesso] = useState('');
  const [title, setTitle] = useState('');
  const [dataFiltro, setDataFiltro] = useState({});
  const [options, setOptions] = useState([]);
  const [filter, setFilter] = useState(0);

  const handleSearch = async () => {
    const responseArea = await areaSelect();
    const responseSubArea = await subareaSelect({});
    const responsePergunta = await perguntaSelect({});

    const response1 = await ApiGetConsulta(
      '/tabela/empresa',
      localStorage.getItem('sessionToken')
    );
    const resEmpresa = response1.data.tabela;

    const response2 = await ApiGetConsulta(
      '/tabela/loja',
      localStorage.getItem('sessionToken')
    );
    const resLoja = response2.data.tabela;

    const response3 = await ApiGetConsulta(
      '/tabela/unidadeAdm',
      localStorage.getItem('sessionToken')
    );
    const resUndAdm = response3.data.tabela;

    setOptions([
      responseArea, //0
      resEmpresa, //1
      resLoja, //2
      resUndAdm, //3
      responseSubArea, //4
      responsePergunta //5
    ]);
  };

  useEffect(() => {
    setDataFiltro({});
  }, [activeTab]);

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <div
      className="modal fade modal-xl"
      id="Menu-Manutencao"
      tabIndex="-1"
      aria-labelledby="lblManutencao"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-scrollable modalDialog">
        <div className="modal-content" style={{ height: '100vh' }}>
          <div className="modal-header" style={{ height: '3rem' }}>
            <h1
              className="modal-title"
              id="lblManutencao"
              style={{ fontSize: props.tamTitulo }}
            >
              Manutenção {title && `${title}`}
            </h1>
            {activeTab === 0 && (
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            )}
          </div>

          <div className="modal-body">
            {activeTab > 0 && (
              <div
                className="offcanvas my-offcanvas"
                tabIndex="-1"
                id="offcanvasTransferencia"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  {activeTab === 2 && (
                    <SearchSubarea
                      dataFiltro={dataFiltro}
                      setDataFiltro={setDataFiltro}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      options={options}
                      filter={filter}
                      setFilter={setFilter}
                    />
                  )}

                  {activeTab === 3 && (
                    <SearchAmbiente
                      dataFiltro={dataFiltro}
                      setDataFiltro={setDataFiltro}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      options={options}
                      filter={filter}
                      setFilter={setFilter}
                    />
                  )}

                  {activeTab === 4 && (
                    <SearchPerguntas
                      dataFiltro={dataFiltro}
                      setDataFiltro={setDataFiltro}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      options={options}
                      filter={filter}
                      setFilter={setFilter}
                    />
                  )}

                  {activeTab === 5 && (
                    <SearchRespostas
                      dataFiltro={dataFiltro}
                      setDataFiltro={setDataFiltro}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      options={options}
                      filter={filter}
                      setFilter={setFilter}
                    />
                  )}

                  {/* {activeTab === 6 && (
                    <SearchPerguntaSubarea
                      dataFiltro={dataFiltro}
                      setDataFiltro={setDataFiltro}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      options={options}
                      filter={filter}
                      setFilter={setFilter}
                    />
                  )} */}
                </div>
              </div>
            )}

            <div className="nav nav-tabs" style={{ marginTop: '-1rem' }}>
              <p style={{ display: 'none' }}>Área de Auditoria</p>
              <p style={{ display: 'none' }}>Sub Área de Auditoria</p>
              <p style={{ display: 'none' }}>Ambiente</p>
              <p style={{ display: 'none' }}>Perguntas</p>
              <p style={{ display: 'none' }}>Respostas</p>
              <p style={{ display: 'none' }}>Questionário Área Pergunta</p>
            </div>

            {activeTab === 0 && (
              <div style={{ width: '100%' }}>
                <div className="row">
                  <div
                    className="col-sm-12 col-md-4 col-lg-3 mb-1"
                    onClick={() => {
                      setTitle('da Área de Auditoria');
                      setActiveTab(1);
                    }}
                  >
                    <div className="card">
                      <div className="card-body d-flex flex-column justify-content-center align-items-center">
                        <h4 style={{ fontSize: props.tamSubTitulo }}>
                          Área de Auditoria
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-sm-12 col-md-4 col-lg-3 mb-1"
                    onClick={() => {
                      setTitle('da Subarea de Auditoria');
                      setActiveTab(2);
                    }}
                  >
                    <div className="card">
                      <div className="card-body d-flex flex-column justify-content-center align-items-center">
                        <h4 style={{ fontSize: props.tamSubTitulo }}>
                          Subarea de Auditoria
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-sm-12 col-md-4 col-lg-3 mb-1"
                    onClick={() => {
                      setTitle('do Ambiente (Questionário)');
                      setActiveTab(3);
                    }}
                  >
                    <div className="card">
                      <div className="card-body d-flex flex-column justify-content-center align-items-center">
                        <h4 style={{ fontSize: props.tamSubTitulo }}>
                          Ambiente
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-sm-12 col-md-4 col-lg-3 mb-1"
                    onClick={() => {
                      setTitle('das Perguntas');
                      setActiveTab(4);
                    }}
                  >
                    <div className="card">
                      <div className="card-body d-flex flex-column justify-content-center align-items-center">
                        <h4 style={{ fontSize: props.tamSubTitulo }}>
                          Perguntas
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-sm-12 col-md-4 col-lg-3 mb-1"
                    onClick={() => {
                      setTitle('das Respostas');
                      setActiveTab(5);
                    }}
                  >
                    <div className="card">
                      <div className="card-body d-flex flex-column justify-content-center align-items-center">
                        <h4 style={{ fontSize: props.tamSubTitulo }}>
                          Respostas
                        </h4>
                      </div>
                    </div>
                  </div>
                  {/* <div
                    className="col-sm-12 col-md-4 col-lg-3 mb-1"
                    onClick={() => {
                      setTitle('de Pergunta Subarea');
                      setActiveTab(6);
                    }}
                  >
                    <div className="card">
                      <div className="card-body d-flex flex-column justify-content-center align-items-center">
                        <h4 style={{ fontSize: props.tamSubTitulo }}>
                          Pergunta Subarea
                        </h4>
                      </div>
                    </div>
                  </div> */}
                  {/* <div
                      className="col-sm-12 col-md-4 col-lg-3 mb-1"
                      onClick={() => {
                        setTitle('de Questionário Área Pergunta');
                        setActiveTab(6);
                      }}
                    >
                      <div className="card">
                        <div className="card-body d-flex flex-column justify-content-center align-items-center">
                          <h4 style={{ fontSize: props.tamSubTitulo }}>
                            Questionário Área Pergunta
                          </h4>
                        </div>
                      </div>
                    </div> */}
                </div>
              </div>
            )}

            <div className="tab-content">
              <div
                className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
                id="tab1"
              >
                <Area
                  tamTexto={props.tamTexto}
                  tamSubTexto={props.tamSubTexto}
                  tabInsert={tabInsert}
                  setTabReturn={setTabReturn}
                  tabReturn={tabReturn}
                  tabUpdate={tabUpdate}
                  setTabUpdate={setTabUpdate}
                  setNvAcesso={setNvAcesso}
                />
              </div>
              <div
                className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
                id="tab2"
              >
                <Subarea
                  tamTexto={props.tamTexto}
                  tamSubTexto={props.tamSubTexto}
                  tabInsert={tabInsert}
                  setTabReturn={setTabReturn}
                  tabReturn={tabReturn}
                  tabUpdate={tabUpdate}
                  setTabUpdate={setTabUpdate}
                  setNvAcesso={setNvAcesso}
                  activeTab={activeTab}
                  dataFiltro={dataFiltro}
                  filter={filter}
                />
              </div>
              <div
                className={`tab-pane ${activeTab === 3 ? 'active' : ''}`}
                id="tab3"
              >
                <Ambiente
                  tamTexto={props.tamTexto}
                  tamSubTexto={props.tamSubTexto}
                  tabInsert={tabInsert}
                  setTabReturn={setTabReturn}
                  tabReturn={tabReturn}
                  tabUpdate={tabUpdate}
                  setTabUpdate={setTabUpdate}
                  setNvAcesso={setNvAcesso}
                  activeTab={activeTab}
                  dataFiltro={dataFiltro}
                  filter={filter}
                  options={options}
                />
              </div>
              <div
                className={`tab-pane ${activeTab === 4 ? 'active' : ''}`}
                id="tab4"
              >
                <Perguntas
                  tamTexto={props.tamTexto}
                  tamSubTexto={props.tamSubTexto}
                  tabInsert={tabInsert}
                  setTabReturn={setTabReturn}
                  tabReturn={tabReturn}
                  tabUpdate={tabUpdate}
                  setTabUpdate={setTabUpdate}
                  setNvAcesso={setNvAcesso}
                  activeTab={activeTab}
                  dataFiltro={dataFiltro}
                  filter={filter}
                />
              </div>
              <div
                className={`tab-pane ${activeTab === 5 ? 'active' : ''}`}
                id="tab5"
              >
                <Respostas
                  tamTexto={props.tamTexto}
                  tamSubTexto={props.tamSubTexto}
                  tabInsert={tabInsert}
                  setTabReturn={setTabReturn}
                  tabReturn={tabReturn}
                  tabUpdate={tabUpdate}
                  setTabUpdate={setTabUpdate}
                  setNvAcesso={setNvAcesso}
                  activeTab={activeTab}
                  dataFiltro={dataFiltro}
                  filter={filter}
                />
              </div>
              {/* <div
                className={`tab-pane ${activeTab === 6 ? 'active' : ''}`}
                id="tab6"
              >
                <PerguntaSubarea
                  tamTexto={props.tamTexto}
                  tamSubTexto={props.tamSubTexto}
                  tabInsert={tabInsert}
                  setTabReturn={setTabReturn}
                  tabReturn={tabReturn}
                  tabUpdate={tabUpdate}
                  setTabUpdate={setTabUpdate}
                  setNvAcesso={setNvAcesso}
                  activeTab={activeTab}
                  dataFiltro={dataFiltro}
                  filter={filter}
                />
              </div> */}
              {/* <div
                className={`tab-pane ${activeTab === 6 ? 'active' : ''}`}
                id="tab6"
              >
                <QuestionarioAreaPergunta
                  tamTexto={props.tamTexto}
                  tamSubTexto={props.tamSubTexto}
                  tabInsert={tabInsert}
                  setTabReturn={setTabReturn}
                  tabReturn={tabReturn}
                  tabUpdate={tabUpdate}
                  setTabUpdate={setTabUpdate}
                  setNvAcesso={setNvAcesso}
                  activeTab={activeTab}
                  dataFiltro={dataFiltro}
                  filter={filter}
                  etapaQuestionarioAreaPergunta={etapaQuestionarioAreaPergunta}
                  setEtapaQuestionarioAreaPergunta={
                    setEtapaQuestionarioAreaPergunta
                  }
                />
              </div> */}
            </div>
          </div>

          <div className="modal-footer">
            {activeTab > 0 && (
              <>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    if (tabReturn > 0) {
                      setTabReturn(0);
                    } else {
                      setTitle('');
                      setActiveTab(0);
                      setDataFiltro({});
                    }
                    setTabInsert(0);
                  }}
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-arrow-left"></i>
                </button>

                {tabReturn === 0 && (
                  <>
                    {activeTab > 1 && (
                      <button
                        className="btn btn-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasTransferencia"
                        aria-controls="offcanvasExample"
                        style={{ fontSize: props.tamTexto }}
                      >
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </button>
                    )}

                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        setTabInsert(activeTab);
                      }}
                      style={{ fontSize: props.tamTexto }}
                    >
                      <i className="fa-solid fa-plus"></i>
                    </button>
                  </>
                )}

                {tabReturn === 1 && nvAcesso !== 'read' && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      setTabUpdate(activeTab);
                    }}
                    style={{ fontSize: props.tamTexto }}
                  >
                    <i className="fa-solid fa-check"></i>
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
