import { useCallback, useEffect, useState } from 'react';

import { ApiPost } from '../services/apiService';
import ChartContainer from './graficos/ChartContainer';
import { Modal2GLinha } from './graficos/Modal2GLinha';

export const Modal2 = ({ OpcoesSequencial }) => {
  // Get the current date
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are zero-based

  // Calculate the date 12 months ago
  const pastDate = new Date();
  pastDate.setMonth(pastDate.getMonth() - 11); // Subtract 11 months to get the start of the 12-month period
  const pastYear = pastDate.getFullYear();
  const pastMonth = pastDate.getMonth() + 1;

  // Format the dates as YYYYMM
  const formatDate = (year, month) => {
    return `${year}${month.toString().padStart(2, '0')}`;
  };

  const initialDataInicial = formatDate(pastYear, pastMonth);
  const initialDataFinal = formatDate(currentYear, currentMonth);
  console.log(initialDataInicial, initialDataFinal);
  const [seq, setSeq] = useState(['00027']);
  const [dataInicial, setDataInicial] = useState(initialDataInicial);
  const [dataFinal, setDataFinal] = useState(initialDataFinal);
  const [graficoBarraData, setGraficoBarraData] = useState([]);

  const prevSeqOptions = OpcoesSequencial.slice().sort((a, b) =>
    a.que_descri.localeCompare(b.que_descri)
  );

  const seqOptions = prevSeqOptions.map((option, index) => {
    return { item: `${option.que_seqque} - ${option.que_descri}` };
  });

  const handleSearch = useCallback(async () => {
    try {
      let useSeq = seq.value || seq;

      const response = await ApiPost(
        '/auditoria/graficomediaambiente/',
        {
          seq: useSeq,
          dateIni: dataInicial,
          dateFim: dataFinal
        },
        localStorage.getItem('sessionToken')
      );
      const newData = response.data.auditoria.map((item) => ({
        data_id: item.anomes,
        sequencial_id: item.que_seqque,
        sequencial_descricao: item.que_descri,
        quantidade: item.qt_palicacoes,
        soma_notas: item.soma_notas,
        media: item.media_mes
      }));
      return newData;
    } catch (err) {
      console.log(err);
    }
  }, [seq, dataInicial, dataFinal]);

  useEffect(() => {
    handleSearch().then((data) => {
      setGraficoBarraData(data);
    });
  }, [handleSearch]);

  useEffect(() => {
    handleSearch()
      .then((data) => {
        const highestQuantidade = data.reduce(
          (max, item) => (item.quantidade > max.quantidade ? item : max),
          data[0]
        );
        setSeq([highestQuantidade.sequencial_id]);
      })
      .catch((err) => {
        console.log(
          `Erro ao buscar dados: ${err}`,
          seq,
          dataInicial,
          dataFinal
        );
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div
        className="modal fade modal-xl"
        id="ModalGrafico2"
        tabIndex="-1"
        aria-labelledby="lblGrafico1"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-scrollable modalDialog">
          <div className="modal-content">
            <div className="modal-header" style={{ height: '3rem' }}>
              <div className="d-flex justify-content-botween align-items-center w-100">
                <i
                  className="fa-solid fa-arrow-left fs-5"
                  data-bs-toggle="modal"
                  data-bs-target="#ModalLista"
                ></i>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </div>

            <ChartContainer
              seqOptions={seqOptions}
              setSeq={setSeq}
              dataInicialFormatted={String(dataInicial)}
              dataFinalFormatted={String(dataFinal)}
              setDataInicial={setDataInicial}
              setDataFinal={setDataFinal}
              isMany={true}
            >
              <Modal2GLinha
                data={graficoBarraData}
                seq={seq}
                mesIni={String(dataInicial).slice(4, 6)}
                anoIni={String(dataInicial).slice(0, 4)}
                mesFim={String(dataFinal).slice(4, 6)}
                anoFim={String(dataFinal).slice(0, 4)}
                titulo={'Evolução da Nota de Auditoria por ambiente/periodo'}
              />
            </ChartContainer>
          </div>
        </div>
      </div>
    </>
  );
};
