import {
  ApiDelete,
  ApiGet,
  ApiPostNew,
  ApiPut
} from '../../../services/apiService';

import Swal from 'sweetalert2';

export async function perguntaPost(data) {
  try {
    const response = await ApiPostNew('/tabela/pergunta', data);
    if (response.data.mensagem.msg.slice(0, 3) === '100') {
      alert('Registro salvo com sucesso!');
      return true;
    }
    alert(response.data.mensagem.msg);
    return false;
  } catch (err) {
    console.log(err);
  }
}

export async function perguntaUpdate(data) {
  try {
    const response = await ApiPut(
      '/tabela/pergunta',
      data,
      localStorage.getItem('sessionToken')
    );
    if (response.data.mensagem.msg.slice(0, 3) === '100') {
      alert('Registro atualizado com sucesso!');
      return true;
    }
    alert(response.data.mensagem.msg);
    return false;
  } catch (err) {
    console.log(err);
  }
}

export async function perguntaDelete(data) {
  await Swal.fire({
    title: 'Deletar registro?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Confirmar'
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        const response = await ApiDelete(
          '/tabela/pergunta',
          data,
          localStorage.getItem('sessionToken')
        );
        if (response.data.mensagem.msg.slice(0, 3) === '100') {
          alert('Registro deletado com sucesso!');
          return true;
        }
        alert(response.data.mensagem.msg);
        return false;
      } catch (err) {
        console.log(err);
      }
    }
  });
}

export async function perguntaSelect(dataFiltro) {
  try {
    const response = await ApiGet(
      '/tabela/pergunta',
      dataFiltro,
      localStorage.getItem('sessionToken')
    );
    return response?.data?.tabela;
  } catch (err) {
    console.log(err);
  }
}
