import { ApiPostLogin } from '../../services/apiService';

const pushLocalStorage = (res) => {
  localStorage.setItem('sessionToken', res.token);
  localStorage.setItem('sessionCodigo', res.codigo);
  localStorage.setItem('sessionUsuario', res.usuario);
  localStorage.setItem('sessionNome', res.nome);
  localStorage.setItem('sessionSenha', res.senha);
  localStorage.setItem('sessionLogado', res.logado);
  localStorage.setItem('sessionNivelDeAcesso', res.nivelDeAcesso);
  localStorage.setItem('sessionNivelDeAdministracao', res.nivelAdm);
  localStorage.setItem('sessionAdm', res.adm);
  localStorage.setItem('sessionNomeAdm', res.unidadeAdm.uad_nomuad);
  localStorage.setItem('sessionEmpresa', res.empresa);
  localStorage.setItem('sessionLoja', res.loja);
  localStorage.setItem('sessionLojaNome', res.lojaNome);
  localStorage.setItem('sessionFantasia', res.emp_info.fantasia);
  localStorage.setItem('sessionRazao', res.emp_info.razao);
  localStorage.setItem('sessionCnpj', res.emp_info.cnpj);
  localStorage.setItem('sessionEndereco', res.emp_info.endereco);
  localStorage.setItem('sessionTelefone', res.emp_info.telefone);
};

export const fLogin = async (user, password, dataBanco, contexto) => {
  try {
    const user_info = dataBanco.user_info;
    const user_info_seg = dataBanco.user_info_seg;

    if (user_info) {
      const response = await ApiPostLogin(
        '/login',
        user,
        password,
        user_info,
        user_info_seg,
        contexto
      );
      if (response.data.mensagem.msg.slice(0, 3) !== '100') {
        alert(response.data.mensagem.msg);
      }

      if (response.data?.usuario[0] == 'Usuario nao existe')
        alert('Usuario nao existe');

      if (response.data.mensagem.msg.slice(0, 3) === '100') {
        pushLocalStorage(response.data.usuario[0]);
        return true;
      }

      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
