import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

export const GraficoBarraLinhaPeriodos = ({
  dataForm,
  mesIni,
  anoIni,
  mesFim,
  anoFim,
  titulo,
  meta = 0
}) => {
  // Agrupa os dados por data_id e id
  const xAxisLabels = [...new Set(dataForm.map((item) => item.data_id))];

  const seriesNames = [...new Set(dataForm.map((item) => item.id))];

  const seriesData = seriesNames.map((seriesName) => {
    return {
      label: seriesName,
      data: xAxisLabels.map((dataId) => {
        const item = dataForm.find(
          (d) => d.data_id === dataId && d.id === seriesName
        );
        return item ? item.value : 0;
      }),
      backgroundColor: xAxisLabels.map((dataId) => {
        const item = dataForm.find(
          (d) => d.data_id === dataId && d.id === seriesName
        );
        return item && item.value < meta ? '#ff4d4d' : '#4d4dff';
      })
    };
  });

  // Adiciona a linha da meta
  const metaLineData = {
    label: 'Meta',
    data: xAxisLabels.map(() => meta),
    type: 'line',
    borderColor: '#33cc33', // verde
    backgroundColor: '#33cc33', // verde
    borderWidth: 2,
    fill: false,
    pointRadius: xAxisLabels.length === 1 ? 5 : 0,
    pointHoverRadius: xAxisLabels.length === 1 ? 5 : 0
  };

  const data = {
    labels: xAxisLabels,
    datasets: [...seriesData, metaLineData]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels: {
          filter: function (legendItem, chartData) {
            return legendItem.text === 'Meta';
          },
          usePointStyle: true,
          pointStyle: 'circle'
        }
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const datasetLabel = context.dataset.label || '';
            const value =
              typeof context.raw === 'number'
                ? context.raw.toFixed(2)
                : context.raw;
            return `${datasetLabel}: ${value}`;
          }
        },
        titleFont: {
          size: 20
        },
        bodyFont: {
          size: 22
        }
      }
    },
    scales: {
      x: {
        ticks: {
          maxRotation: 45,
          minRotation: 45
        },
        title: {
          display: false,
          text: 'Ambientes'
        }
      },
      y: {
        title: {
          display: true,
          text: 'Nota'
        }
      }
    }
  };

  return (
    <div
      style={{
        height: '90%',
        width: '100%',
        minWidth: '360px',
        minHeight: '400px'
      }}
    >
      <h3 style={{ textAlign: 'center', color: '#000' }}>{titulo}</h3>
      <h4 style={{ textAlign: 'center', color: '#000' }}>
        Período {mesIni}/{anoIni} - {mesFim}/{anoFim}
      </h4>
      <Bar data={data} options={options} />
    </div>
  );
};
