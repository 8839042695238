export let menu_condicao = [
  {
    auditoria: {
      coluna: '1',
      linha: '1',
      nome: 'Auditoria',
      situacao: 'INATIVO',
      status: 'PROD',
      chave: 'Menu-Auditoria'
    }
  },
  {
    manutencao: {
      coluna: '2',
      linha: '1',
      nome: 'Manutenção',
      situacao: 'INATIVO',
      status: 'PROD',
      chave: 'Menu-Manutencao'
    }
  }
];

menu_condicao.sort((a, b) => {
  const keyA = Object.keys(a)[0];
  const keyB = Object.keys(b)[0];
  return a[keyA].linha - b[keyB].linha;
});
