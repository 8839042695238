import { useEffect, useState } from 'react';

import { Cadastro } from './ambiente/Cadastro';
import { Grid } from './ambiente/Grid';
import {
  ambientePost,
  ambienteSelect,
  ambienteUpdate,
  getDisplayedPageNumbers
} from './functions';

export const Ambiente = (props) => {
  const [activeTab, setActiveTab] = useState(1);
  const [dataGrid, setDataGrid] = useState([]);
  const [insertOrUpdate, setInsertOrUpdate] = useState(null);
  const [data, setData] = useState({
    usuario: localStorage.getItem('sessionUsuario')
  });

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage;

  const displayedPageNumbers = getDisplayedPageNumbers(currentPage, pageCount);

  const handleSearch = async (dataFiltro) => {
    setDataGrid(await ambienteSelect(dataFiltro));
  };

  const handleSort = (order, direction) => {
    let multiplier = direction === 'asc' ? 1 : -1;
    switch (order) {
      case 'codigo':
        setDataGrid(
          [...dataGrid].sort(
            (a, b) => multiplier * a.codigo.localeCompare(b.codigo)
          )
        );
        break;
      case 'descricao':
        setDataGrid(
          [...dataGrid].sort(
            (a, b) => multiplier * a.descricao.localeCompare(b.descricao)
          )
        );
        break;
      case 'status':
        setDataGrid(
          [...dataGrid].sort(
            (a, b) => multiplier * a.status.localeCompare(b.status)
          )
        );
        break;
      default:
        setDataGrid([...dataGrid]);
        break;
    }
  };

  useEffect(() => {
    if (props.activeTab === 3) {
      handleSearch(props.dataFiltro);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeTab, props.filter]);

  useEffect(() => {
    if (dataGrid != undefined) {
      setFilteredData(dataGrid.slice(offset, offset + itemsPerPage));
      setPageCount(Math.ceil(dataGrid.length / itemsPerPage));
      setCurrentPage(0);
      setSelectedRow(null);
    }
    // eslint-disable-next-line
  }, [dataGrid]);

  useEffect(() => {
    if (dataGrid !== undefined) {
      setFilteredData(dataGrid.slice(offset, offset + itemsPerPage));
    }
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    if (props.tabInsert === 3) {
      const data = new Date();
      const ano = data.getFullYear();
      const mes = String(data.getMonth() + 1).padStart(2, '0');
      const dia = String(data.getDate()).padStart(2, '0');

      const hora = data.getHours();
      const minuto = String(data.getMinutes()).padStart(2, '0');
      const segundo = String(data.getSeconds()).padStart(2, '0');

      setData({
        ...data,
        usuario: localStorage.getItem('sessionUsuario'),
        dataInclusao: `${ano}-${mes}-${dia}`,
        dataAlteracao: `${ano}-${mes}-${dia}`,
        horaAlteracao: `${hora}:${minuto}:${segundo}`,
        horaInclusao: `${hora}:${minuto}:${segundo}`
      });
      setInsertOrUpdate('insert');
      props.setNvAcesso('insert');
      setActiveTab(2);
      props.setTabReturn(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabInsert]);

  useEffect(() => {
    if (props.tabReturn === 0) {
      setActiveTab(1);
      setData({ usuario: localStorage.getItem('sessionUsuario') });
    }
  }, [props.tabReturn]);

  useEffect(() => {
    if (props.tabUpdate === 3) {
      const fetch = async () => {
        if (insertOrUpdate === 'insert') await ambientePost(data);
        else await ambienteUpdate(data);

        setData({
          usuario: localStorage.getItem('sessionUsuario')
        });

        await handleSearch();

        setActiveTab(1);
        props.setTabReturn(0);
        props.setTabUpdate(0);
      };
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabUpdate]);

  return (
    <div>
      <div>
        <div className="nav nav-tabs">
          <p
            className={`nav-link ${
              activeTab === 1 ? 'text-primary active' : 'text-dark'
            }`}
            onClick={() => setActiveTab(1)}
            style={{ display: 'none' }}
          >
            {/* Tabela */}
          </p>
          <p
            className={`nav-link ${
              activeTab === 2 ? 'text-primary active' : 'text-dark'
            }`}
            onClick={() => setActiveTab(2)}
            style={{ display: 'none' }}
          >
            {/* Insert */}
          </p>
        </div>

        <div className="tab-content">
          <div
            className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
            id="tab1"
          >
            <Grid
              filteredData={filteredData}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageCount={pageCount}
              displayedPageNumbers={displayedPageNumbers}
              setActiveTab={setActiveTab}
              data={data}
              setData={setData}
              dataGrid={dataGrid}
              setInsertOrUpdate={setInsertOrUpdate}
              handleSearch={handleSearch}
              tamTexto={props.tamTexto}
              tamSubTexto={props.tamSubTexto}
              setTabReturn={props.setTabReturn}
              setNvAcesso={props.setNvAcesso}
              handleSort={handleSort}
            />
          </div>
          <div
            className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
            id="tab2"
          >
            <Cadastro
              data={data}
              setData={setData}
              insertOrUpdate={insertOrUpdate}
              options={props.options}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
