export const Cadastro = ({ data, setData, optionsUndadm, insertOrUpdate }) => {
  return (
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-12 row">
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Cód. da Pergunta</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.id || ''}
            disabled
          />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6">
          <label className="form-label">Descrição da Pergunta</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.descricao || ''}
            onChange={(e) => setData({ ...data, descricao: e.target.value })}
            disabled={insertOrUpdate === 'read' && true}
          />
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4">
          <label className="form-label">Abreviação da Pergunta</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.abreviacao || ''}
            onChange={(e) => setData({ ...data, abreviacao: e.target.value })}
            disabled={insertOrUpdate === 'read' && true}
          />
        </div>
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Condicão</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.condicao || ''}
            onChange={(e) => setData({ ...data, condicao: e.target.value })}
            disabled={insertOrUpdate === 'read' && true}
          />
        </div>
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Múltipla Resposta</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.multiplaResposta || ''}
            onChange={(e) =>
              setData({ ...data, multiplaResposta: e.target.value })
            }
            disabled={insertOrUpdate === 'read' && true}
          />
        </div>
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Palavra Chave</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.palavraChave || ''}
            onChange={(e) => setData({ ...data, palavraChave: e.target.value })}
            disabled={insertOrUpdate === 'read' && true}
          />
        </div>
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Relevância</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.relevancia || ''}
            onChange={(e) => setData({ ...data, relevancia: e.target.value })}
            disabled={insertOrUpdate === 'read' && true}
          />
        </div>
      </div>

      <div className="col-sm-12 col-md-12 col-lg-12 row">
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Usuário</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.usuario || ''}
            disabled
          />
        </div>
      </div>

      <div className="col-sm-12 col-md-12 col-lg-12 row">
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Dt. Inclusão</label>
          <input
            type="date"
            className="form-control form-control-sm border border-dark"
            value={data?.dataInclusao || ''}
            disabled
          />
        </div>
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Hr. Inclusão</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.horaInclusao || ''}
            disabled
          />
        </div>
      </div>

      <div className="col-sm-12 col-md-12 col-lg-12 row">
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Dt. Atualização</label>
          <input
            type="date"
            className="form-control form-control-sm border border-dark"
            value={data?.dataAtualizacao || ''}
            disabled
          />
        </div>
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Hr. Atualização</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.horaAtualizacao || ''}
            disabled
          />
        </div>
      </div>
    </div>
  );
};
