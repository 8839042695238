import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { CLogin } from './components/CLogin';
import Dashboard from './components/Dashboards';
import Header from './components/Header';
import { Menu } from './components/Menu';

import environment from './environment.json';
import { ApiMaster } from './services/apiService';

import { addDays } from 'date-fns';
import format from 'date-fns/format';

function App() {
  const [login, setLogin] = useState(false);
  const [dataEmpresa, setDataEmpresa] = useState([]);

  const [dataBanco, setDataBanco] = useState({});

  const [area, setArea] = useState();
  const [campoArea, setCampoArea] = useState(environment.Area);

  const area_verificar = (area) => {
    if (area === 'COMER') setArea('COMERCIO');
    else if (area === 'HOTEL') setArea('HOTEL');
    else if (area === 'RESTA') setArea('RESTAURANTE');
    else if (area === 'SERVI') setArea('SERVIÇO');
  };

  const abr_area = () => {
    if (area === 'COMERCIO') setCampoArea('COMER');
    else if (area === 'HOTEL') setCampoArea('HOTEL');
    else if (area === 'RESTAURANTE') setCampoArea('RESTA');
    else if (area === 'SERVIÇO') setCampoArea('SERVI');
  };

  const [bgDashboard1, setBgDashboard1] = useState('#0d6efd');
  const [fontDashboard1, setFontDashboard1] = useState('#000000');
  const [bgDashboard2, setBgDashboard2] = useState('#eee333');
  const [fontDashboard2, setFontDashboard2] = useState('#000000');
  const [bgDashboard3, setBgDashboard3] = useState('#444fff');
  const [fontDashboard3, setFontDashboard3] = useState('#000000');
  const [bgMenu, setBgMenu] = useState('#ffffff');
  const [fontMenu, setFontMenu] = useState('#000000');

  const [selectedPeriod, setSelectedPeriod] = useState('Essa Semana');
  const [datPeriodDashboardIni, setDatPeriodDashboardIni] = useState('');
  const [datPeriodDashboardEnd, setDatPeriodDashboardEnd] = useState('');

  const [changeState, setChangeState] = useState('');
  // const [tamInput, setTamInput] = useState('1.25rem');
  const [tamInput, setTamInput] = useState('1.10rem');
  const [tamTitulo, setTamTitulo] = useState('1.25rem');
  const [tamSubTitulo, setTamSubTitulo] = useState('1.12rem');
  // const [tamTexto, setTamTexto] = useState('1.05rem');
  const [tamTexto, setTamTexto] = useState('0.9rem');
  // const [tamSubTexto, setTamSubTexto] = useState('0.9rem');
  const [tamSubTexto, setTamSubTexto] = useState('0.7rem');

  const [isFuncCalled, setIsFuncCalled] = useState(false);
  const [token, setToken] = useState('');

  useEffect(() => {
    if (changeState && !isFuncCalled) {
      Templates(changeState);
      setIsFuncCalled(true);
    }
  }, [changeState, isFuncCalled]);

  const handlePeriodClick = (period) => {
    const today = new Date();
    let start, end;
    switch (period) {
      case 'esta-semana':
        start = today;
        while (start.getDay() !== 0) {
          start = addDays(start, -1);
        }
        end = addDays(start, 6);
        setSelectedPeriod('EssaSemana');
        break;
      case 'semana-passada':
        start = today;
        while (start.getDay() !== 0) {
          start = addDays(start, -1);
        }
        start = addDays(start, -7);
        end = addDays(start, 6);
        setSelectedPeriod('SemanaPassada');
        break;
      case 'proxima-semana':
        start = today;
        while (start.getDay() !== 0) {
          start = addDays(start, 1);
        }
        end = addDays(start, 6);
        setSelectedPeriod('ProximaSemana');
        break;
      case 'este-mes':
        start = new Date(today.getFullYear(), today.getMonth(), 1);
        end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        setSelectedPeriod('EsseMes');
        break;
      case 'mes-passado':
        start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        end = new Date(today.getFullYear(), today.getMonth(), 0);
        setSelectedPeriod('MesPassado');
        break;
      case 'proximo-mes':
        start = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        end = new Date(today.getFullYear(), today.getMonth() + 2, 0);
        setSelectedPeriod('ProximoMes');
        break;
      case 'este-ano':
        start = new Date(today.getFullYear(), 0, 1);
        end = new Date(today.getFullYear(), 11, 31);
        setSelectedPeriod('EsseAno');
        break;
      case 'ano-passado':
        start = new Date(today.getFullYear() - 1, 0, 1);
        end = new Date(today.getFullYear() - 1, 11, 31);
        setSelectedPeriod('AnoPassado');
        break;
      case 'proximo-ano':
        start = new Date(today.getFullYear() + 1, 0, 1);
        end = new Date(today.getFullYear() + 1, 11, 31);
        setSelectedPeriod('ProximoAno');
        break;
      default:
        start = null;
        end = null;
        console.log(end);
    }
    setDatPeriodDashboardIni(start ? format(start, 'dd/MM/yyyy') : '');
    setDatPeriodDashboardEnd(end ? format(end, 'dd/MM/yyyy') : '');
  };

  useEffect(() => {
    const savedConfigString = localStorage.getItem('config');
    if (savedConfigString) {
      const savedConfig = JSON.parse(savedConfigString);
      setBgDashboard1(savedConfig.bgDashboard1);
      setFontDashboard1(savedConfig.fontDashboard1);
      setBgDashboard2(savedConfig.bgDashboard2);
      setFontDashboard2(savedConfig.fontDashboard2);
      setBgDashboard3(savedConfig.bgDashboard3);
      setFontDashboard3(savedConfig.fontDashboard3);
      setBgMenu(savedConfig.bgMenu);
      setFontMenu(savedConfig.fontMenu);
    }

    handlePeriodClick('este-ano');
    setChangeState(environment.Tamanho_fonte);

    // ? Apagar os dados do localstorage e cache para evitar os erros que estava tendo antes
    const handlePageUnload = () => {
      localStorage.clear();

      if (caches && caches.keys) {
        caches.keys().then((cacheNames) => {
          cacheNames.forEach((cacheName) => {
            caches.delete(cacheName);
          });
        });
      }
    };

    window.addEventListener('beforeunload', handlePageUnload);
    return () => {
      window.removeEventListener('beforeunload', handlePageUnload);
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem('sessionToken')) {
      setToken(localStorage.getItem('sessionToken'));
    }
  }, [login]);

  function Templates(tam) {
    setChangeState(tam);
    switch (tam) {
      case 'pequeno':
        setTamInput('1.10rem');
        setTamTitulo('1.25rem');
        setTamSubTitulo('1.12rem');
        setTamTexto('0.9rem');
        // setTamSubTexto('0.9rem');
        setTamSubTexto('0.7rem');
        break;
      case 'medio':
        setTamInput('1.5rem');
        setTamTitulo('1.5rem');
        setTamSubTitulo('1.25rem');
        setTamTexto('1.1rem');
        setTamSubTexto('1rem');
        break;
      case 'grande':
        setTamInput('2rem');
        setTamTitulo('2.5rem');
        setTamSubTitulo('1.75rem');
        setTamTexto('1.3rem');
        setTamSubTexto('1.1rem');
        break;
      default:
        break;
    }
  }

  const handleEmpresa = async (id) => {
    try {
      const response = await ApiMaster('get', `/empresa/${id}`);
      localStorage.setItem(
        'sessionMeta',
        response.data.empresa.user.audit_5s_meta
      );
      setDataBanco({
        user_info: response?.data?.empresa?.user_info,
        user_info_seg: response?.data?.empresa?.user_info_seg
      });
      setDataEmpresa(response?.data?.empresa?.user);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    area_verificar(dataEmpresa.area);
    if (area) abr_area(area);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEmpresa, area]);

  return (
    <>
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: '100vh', width: '100vw' }}
              >
                ESCOLHER EMPRESA
              </div>
            }
          />
          <Route
            path="/:id"
            element={
              <>
                {!login ? (
                  <CLogin
                    dataBanco={dataBanco}
                    handleEmpresa={handleEmpresa}
                    setLogin={setLogin}
                  />
                ) : (
                  <>
                    <Header
                      dataEmpresa={dataEmpresa}
                      handleEmpresa={handleEmpresa}
                      login={login}
                      setLogin={setLogin}
                      bgDashboard1={bgDashboard1}
                      setBgDashboard1={setBgDashboard1}
                      fontDashboard1={fontDashboard1}
                      setFontDashboard1={setFontDashboard1}
                      bgDashboard2={bgDashboard2}
                      setBgDashboard2={setBgDashboard2}
                      fontDashboard2={fontDashboard2}
                      setFontDashboard2={setFontDashboard2}
                      bgDashboard3={bgDashboard3}
                      setBgDashboard3={setBgDashboard3}
                      fontDashboard3={fontDashboard3}
                      setFontDashboard3={setFontDashboard3}
                      bgMenu={bgMenu}
                      setBgMenu={setBgMenu}
                      fontMenu={fontMenu}
                      setFontMenu={setFontMenu}
                      handlePeriodClick={handlePeriodClick}
                      selectedPeriod={selectedPeriod}
                      tamInput={tamInput}
                      setTamInput={setTamInput}
                      tamTitulo={tamTitulo}
                      setTamTitulo={setTamTitulo}
                      tamSubTitulo={tamSubTitulo}
                      setTamSubTitulo={setTamSubTitulo}
                      tamTexto={tamTexto}
                      setTamTexto={setTamTexto}
                      tamSubTexto={tamSubTexto}
                      setTamSubTexto={setTamSubTexto}
                      changeState={changeState}
                      setChangeState={setChangeState}
                      dataBanco={dataBanco}
                      setDataBanco={setDataBanco}
                      campoArea={campoArea}
                      setCampoArea={setCampoArea}
                      area_verificar={area_verificar}
                      abr_area={abr_area}
                      area={area}
                      setArea={setArea}
                      Templates={Templates}
                    />
                    <Dashboard
                      token={token}
                      bgDashboard1={bgDashboard1}
                      fontDashboard1={fontDashboard1}
                      bgDashboard2={bgDashboard2}
                      fontDashboard2={fontDashboard2}
                      bgDashboard3={bgDashboard3}
                      fontDashboard3={fontDashboard3}
                      login={login}
                      datPeriodDashboardIni={datPeriodDashboardIni}
                      datPeriodDashboardEnd={datPeriodDashboardEnd}
                      tamTitulo={tamTitulo}
                      tamSubTitulo={tamSubTitulo}
                      tamTexto={tamTexto}
                      tamSubTexto={tamSubTexto}
                      campoArea={campoArea}
                      dataBanco={dataBanco}
                      setDataBanco={setDataBanco}
                      dataEmpresa={dataEmpresa}
                    />
                    <Menu
                      token={token}
                      bgMenu={bgMenu}
                      fontMenu={fontMenu}
                      login={login}
                      tamInput={tamInput}
                      tamTitulo={tamTitulo}
                      tamSubTitulo={tamSubTitulo}
                      tamTexto={tamTexto}
                      tamSubTexto={tamSubTexto}
                      dataBanco={dataBanco}
                      setDataBanco={setDataBanco}
                      dataEmpresa={dataEmpresa}
                    />
                  </>
                )}
              </>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
