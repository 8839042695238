export const SearchSubarea = (props) => {
  return (
    <>
      <div>
        <span className="col-form-label">Área</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.id || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                id: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.options[0]?.map((option, index) => (
              <option key={index} value={option.are_codare}>
                {option.are_codare} - {option.are_descri}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* button */}
      <div className="row mt-2">
        <div className="col-12 d-flex align-items-center justify-content-end">
          <button
            className="btn btn-primary"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => props.setFilter(props.filter + 1)}
          >
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div>
    </>
  );
};
