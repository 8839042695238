import { useCallback, useEffect, useState } from 'react';

import { ApiPost } from '../services/apiService';
import ChartContainer from './graficos/ChartContainer';
import { GraficoBarraLinha } from './graficos/GraficoBarraLinha';
import { GraficoBarraSemData } from './graficos/GraficoBarraSemData';

export const Modal6 = ({ OpcoesSequencial }) => {
  const [seq, setSeq] = useState([]);
  const [dataInicial, setDataInicial] = useState(202401);
  const [dataFinal, setDataFinal] = useState(202401);
  const [graficoBarraData, setGraficoBarraData] = useState([]);

  const prevSeqOptions = OpcoesSequencial.slice().sort((a, b) =>
    a.que_descri.localeCompare(b.que_descri)
  );

  const seqOptions = prevSeqOptions.map((option, index) => {
    return { item: `${option.que_seqque} - ${option.que_descri}` };
  });

  const handleSearch = useCallback(async () => {
    try {
      let useSeq = seq.value || seq;
      const response = await ApiPost(
        '/auditoria/graficomediaambiente/',
        {
          seq: useSeq,
          dateIni: dataInicial,
          dateFim: dataInicial
        },
        localStorage.getItem('sessionToken')
      );
      const newData = response.data.auditoria.map((item) => ({
        data_id: item.anomes,
        sequencial_id: item.que_seqque,
        id: item.que_descri,
        quantidade: item.qt_palicacoes,
        soma_notas: item.soma_notas,
        value: item.media_mes
      }));
      return newData;
    } catch (err) {
      console.log(err);
    }
  }, [seq, dataInicial]);

  useEffect(() => {
    handleSearch().then((data) => {
      const sortedData = data.sort((a, b) => b.value - a.value);
      setGraficoBarraData(sortedData);
    });
  }, [handleSearch]);

  return (
    <>
      <div
        className="modal fade modal-xl"
        id="ModalGrafico6"
        tabIndex="-1"
        aria-labelledby="lblGrafico1"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-scrollable modalDialog">
          <div className="modal-content">
            <div className="modal-header" style={{ height: '3rem' }}>
              <div className="d-flex justify-content-botween align-items-center w-100">
                <i
                  className="fa-solid fa-arrow-left fs-5"
                  data-bs-toggle="modal"
                  data-bs-target="#ModalLista"
                ></i>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </div>

            <ChartContainer
              seqOptions={seqOptions}
              setSeq={setSeq}
              dataInicialFormatted={String(dataInicial)}
              dataFinalFormatted={String(dataFinal)}
              setDataInicial={setDataInicial}
              setDataFinal={setDataFinal}
              isMany={true}
              isDataFinal={false}
            >
              <GraficoBarraLinha
                dataForm={graficoBarraData}
                meta={parseInt(localStorage.getItem('sessionMeta'))}
                mesIni={String(dataInicial).slice(4, 6)}
                anoIni={String(dataInicial).slice(0, 4)}
                titulo={'Nota de Auditoria por ambiente'}
              />
            </ChartContainer>
          </div>
        </div>
      </div>
    </>
  );
};
