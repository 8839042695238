import { useCallback, useEffect, useState } from 'react';

import { ApiPost } from '../services/apiService';
import ChartContainer from './graficos/ChartContainer';
import { GraficoEvolucaoNotaAuditoria } from './graficos/GraficoEvolucaoNotaAuditoria';

export const Modal1 = ({ OpcoesSequencial }) => {
  const [seq, setSeq] = useState('00016');
  const [dataInicial, setDataInicial] = useState(202301);
  const [dataFinal, setDataFinal] = useState(202401);
  const [graficoLinhaData, setGraficoLinhaData] = useState([]);

  const prevSeqOptions = OpcoesSequencial.slice().sort((a, b) =>
    a.que_descri.localeCompare(b.que_descri)
  );

  const seqOptions = prevSeqOptions.map((option, index) => {
    return { item: `${option.que_seqque} - ${option.que_descri}` };
  });

  const handleSearch = useCallback(async () => {
    try {
      let useSeq = seq.value || seq;
      const response = await ApiPost(
        '/auditoria/graficonotageral/',
        {
          que_seqque: useSeq,
          dateIni: dataInicial,
          dateFim: dataFinal
        },
        localStorage.getItem('sessionToken')
      );
      return response.data.auditoria;
    } catch (err) {
      console.log(err);
    }
  }, [seq, dataInicial, dataFinal]);

  useEffect(() => {
    handleSearch().then((data) => {
      setGraficoLinhaData(data);
    });
  }, [handleSearch]);

  return (
    <>
      <div
        className="modal fade modal-xl"
        id="ModalGrafico1"
        tabIndex="-1"
        aria-labelledby="lblGrafico1"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-scrollable modalDialog">
          <div className="modal-content">
            <div className="modal-header" style={{ height: '3rem' }}>
              <div className="d-flex justify-content-botween align-items-center w-100">
                <i
                  className="fa-solid fa-arrow-left fs-5"
                  data-bs-toggle="modal"
                  data-bs-target="#ModalLista"
                ></i>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </div>

            <ChartContainer
              seqOptions={seqOptions}
              setSeq={setSeq}
              dataInicialFormatted={String(dataInicial)}
              dataFinalFormatted={String(dataFinal)}
              setDataInicial={setDataInicial}
              setDataFinal={setDataFinal}
            >
              <GraficoEvolucaoNotaAuditoria
                data={graficoLinhaData}
                seq={seq}
                mesIni={String(dataInicial).slice(4, 6)}
                anoIni={String(dataInicial).slice(0, 4)}
                mesFim={String(dataFinal).slice(4, 6)}
                anoFim={String(dataFinal).slice(0, 4)}
              />
            </ChartContainer>
          </div>
        </div>
      </div>
    </>
  );
};
