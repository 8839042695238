import axios from 'axios';

// apiSalt
// const urlSalt = 'http://192.168.1.6:3003';
// const urlSalt = 'http://192.168.1.35:3005';

// const urlSalt = 'https://siegestao.com.br:21074';
// const urlSalt = 'https://sie.app.br:21074'; // prod
const urlSalt = 'https://sie.app.br:21080'; // builder

const apiSalt = axios.create({
  baseURL: urlSalt
});

apiSalt.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('sessionToken');
    }
    return error;
  }
);

// api Rafael
const urlrj = 'https://oficialfood.com.br:21010/';
const apirj = axios.create({
  baseURL: urlrj
});

apirj.interceptors.request.use(
  (req) => {
    if (localStorage.getItem('sessionToken')) {
      req.headers.Authorization = `Bearer ${localStorage.getItem(
        'sessionToken'
      )}`;
    }

    return req;
  },
  (err) => {
    console.log(err);
  }
);

apirj.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('sessionToken');

      if (!document.location.href.includes('/login', 1)) {
        document.location = '/login';
      }
    }
    return error;
  }
);

export { apiSalt, apirj };
