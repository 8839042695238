const BlcTitle = (props) => {
  return (
    <th scope="col" className="grid">
      <div className="d-flex justify-content-between align-items-center">
        <span style={{ fontSize: props.tamSubTexto }}>{props.title}</span>
        <div className="ms-1 text-nowrap">
          <span
            className="me-1"
            onClick={() => props.handleSort(props.order, 'asc')}
          >
            <i className="fa-solid fa-arrow-up"></i>
          </span>
          <span onClick={() => props.handleSort(props.order, 'desc')}>
            <i className="fa-solid fa-arrow-down"></i>
          </span>
        </div>
      </div>
    </th>
  );
};

export const Grid = (props) => {
  const campos = [
    { title: 'Seq', order: 'seq' },
    { title: 'Ambiente', order: 'ambiente' }
  ];

  return (
    <table
      className="table table-striped table-hover table-bordered table-sm align-middle"
      style={{ height: '100%' }}
    >
      <thead>
        <tr className="table-secondary">
          {campos.map((campo) => (
            <BlcTitle
              key={campo.order}
              title={campo.title}
              order={campo.order}
              handleSort={props.handleSort}
              tamTexto={props.tamSubTexto}
            />
          ))}
          <th style={{ width: '80px' }} scope="col" className="grid"></th>
        </tr>
      </thead>
      <tbody>
        {props.filteredData.map((data, index) => (
          <tr
            key={index}
            className={props.selectedRow === index ? 'bg-info' : ''}
            onClick={() => props.handleRowClick(index)}
          >
            <th scope="row" style={{ fontSize: props.tamSubTexto }}>
              {data.qap_seqque}
            </th>
            <td style={{ fontSize: props.tamSubTexto }}>{data.que_descri}</td>
            <td>
              <div>
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm"
                  style={{ fontSize: props.tamSubTexto }}
                  onClick={() =>
                    props.handleEdit(data.que_descri, data.qap_seqque)
                  }
                  disabled={props.isLoadingEdit}
                >
                  {props.isLoadingEdit ? (
                    <i className="fa-solid fa-hourglass-start"></i>
                  ) : (
                    'ENTRAR'
                  )}
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="10" className="align-middle">
            <nav className="d-flex align-items-center justify-content-center">
              <ul className="h-100 pagination pagination-sm">
                <li className="page-item">
                  <button
                    className="page-link"
                    aria-label="PreviousPage"
                    onClick={() => props.handleFirstPage()}
                  >
                    <span aria-hidden="true">&laquo;</span>
                  </button>
                </li>
                <li className="page-item">
                  <button
                    className="page-link"
                    aria-label="PreviousIten"
                    onClick={() => props.handlePageClick(props.currentPage - 1)}
                  >
                    <span aria-hidden="true">&lt;</span>
                  </button>
                </li>
                {props.displayedPageNumbers.map((pageNumber) => (
                  <li
                    key={pageNumber}
                    className={`page-item ${
                      pageNumber === props.currentPage ? 'active' : ''
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => props.handlePageClick(pageNumber)}
                    >
                      {pageNumber + 1}
                    </button>
                  </li>
                ))}
                <li className="page-item">
                  <button
                    className="page-link"
                    aria-label="NextIten"
                    onClick={() => props.handlePageClick(props.currentPage + 1)}
                  >
                    <span aria-hidden="true">&gt;</span>
                  </button>
                </li>
                <li className="page-item">
                  <button
                    className="page-link"
                    aria-label="NextPage"
                    onClick={() => props.handleLastPage()}
                  >
                    <span aria-hidden="true">&raquo;</span>
                  </button>
                </li>
              </ul>
              <div className="d-flex flex-column">
                <p
                  className="text-muted ms-2"
                  style={{ fontSize: props.tamTexto }}
                >
                  Quantidade de Ambientes:{' '}
                  {props.contRegistros.toLocaleString('pt-BR', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })}
                </p>
              </div>
            </nav>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};
