import React from 'react';

import { FiltrosDate } from './FiltroDate';
import { FiltroDropdown } from './FiltroDropdown';

const ChartContainer = ({
  children,
  seqOptions,
  setSeq,
  dataInicialFormatted,
  dataFinalFormatted,
  setDataInicial,
  setDataFinal,
  title = 'Ambiente:',
  isMany = false,
  isDataFinal = true
}) => {
  const [startMonth, setStartMonth] = React.useState(
    dataInicialFormatted.slice(4, 6)
  );
  const [endMonth, setEndMonth] = React.useState(
    dataFinalFormatted.slice(4, 6)
  );
  const [startYear, setStartYear] = React.useState(
    dataInicialFormatted.slice(0, 4)
  );
  const [endYear, setEndYear] = React.useState(dataFinalFormatted.slice(0, 4));

  return (
    <div
      className="container mt-2"
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflow: 'auto'
      }}
    >
      <div
        className="d-flex flex-column flex-md-row"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <FiltroDropdown
          listaItems={seqOptions}
          setValue={setSeq}
          title={title}
          isMany={isMany}
        />
        <FiltrosDate
          startMonth={startMonth}
          setStartMonth={setStartMonth}
          startYear={startYear}
          setStartYear={setStartYear}
          endMonth={endMonth}
          setEndMonth={setEndMonth}
          endYear={endYear}
          setEndYear={setEndYear}
          setDataInicial={setDataInicial}
          setDataFinal={setDataFinal}
          isDataFinal={isDataFinal}
        />
      </div>
      <div className="modal-body" style={{ flex: '1 1 auto' }}>
        <div
          className="container"
          style={{
            height: '100%',
            width: '100%',
            margin: 'auto'
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ChartContainer;
