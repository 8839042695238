export const SearchPerguntas = (props) => {
  return (
    <>
      <div>
        <span className="col-form-label">Código Pergunta</span>
        <div>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.id || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                id: e.target.value
              })
            }
          />
        </div>
      </div>

      <div>
        <span className="col-form-label">Descrição Pergunta</span>
        <div>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.descricao || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                descricao: e.target.value.toUpperCase()
              })
            }
            style={{ textTransform: 'uppercase' }}
          />
        </div>
      </div>

      {/* button */}
      <div className="row mt-2">
        <div className="col-12 d-flex align-items-center justify-content-end">
          <button
            className="btn btn-primary"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => props.setFilter(props.filter + 1)}
          >
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div>
    </>
  );
};
