export const Cadastro = ({ data, setData, insertOrUpdate }) => {
  return (
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-12 row">
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Cód. da Área</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.areaID || ''}
            disabled
          />
        </div>
        <div className="col-sm-12 col-md-8 col-lg-8">
          <label className="form-label">Descrição da Área</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.descricao || ''}
            onChange={(e) => setData({ ...data, descricao: e.target.value })}
            disabled={insertOrUpdate === 'read' && true}
          />
        </div>
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Abreviação</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.abreviacao || ''}
            onChange={(e) => setData({ ...data, abreviacao: e.target.value })}
            disabled={insertOrUpdate === 'read' && true}
          />
        </div>
      </div>

      <div className="col-sm-12 col-md-12 col-lg-12 row">
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Usuário</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.usuario || ''}
            disabled
          />
        </div>
      </div>

      <div className="col-sm-12 col-md-12 col-lg-12 row">
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Dt. Inclusão</label>
          <input
            type="date"
            className="form-control form-control-sm border border-dark"
            value={data?.datincl || ''}
            disabled
          />
        </div>
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Hr. Inclusão</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.horincl || ''}
            disabled
          />
        </div>
      </div>

      <div className="col-sm-12 col-md-12 col-lg-12 row">
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Dt. Atualização</label>
          <input
            type="date"
            className="form-control form-control-sm border border-dark"
            value={data?.datatz || ''}
            disabled
          />
        </div>
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Hr. Atualização</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.horatz || ''}
            disabled
          />
        </div>
      </div>
    </div>
  );
};
