import { apiSalt } from './api';

async function ApiGet(cam, body, token) {
  try {
    const response = await apiSalt.get(cam, {
      params: body,
      headers: { Authorization: token }
    });
    return response;
  } catch (err) {
    console.log(err);
  }
}

async function ApiGetConsulta(cam, token) {
  try {
    const response = await apiSalt.get(cam, {
      headers: { Authorization: token }
    });
    return response;
  } catch (err) {
    console.log(err);
  }
}

async function ApiPost(
  cam,
  tipo,
  token,
  sie_servidor,
  sie_porta,
  sie_nome,
  login,
  senha
) {
  try {
    const response = await apiSalt.post(
      cam,
      {
        tipo: tipo,
        sie_servidor: sie_servidor,
        sie_porta: sie_porta,
        sie_nome: sie_nome,
        login: login,
        senha: senha
      },
      { headers: { Authorization: token } }
    );
    return response;
  } catch (err) {
    console.log(err);
  }
}

async function ApiPostNew(url, body) {
  try {
    const response = await apiSalt.post(url, body, {
      headers: { Authorization: localStorage.getItem('sessionToken') }
    });
    return response;
  } catch (err) {
    console.log(err);
  }
}

async function ApiPostLogin(
  cam,
  name,
  password,
  user_info,
  user_info_seg,
  contexto
) {
  try {
    const response = await apiSalt.post(cam, {
      name: name,
      password: password,
      user_info: user_info,
      user_info_seg: user_info_seg,
      contexto: contexto
    });
    return response;
  } catch (err) {
    console.log(err);
  }
}

async function ApiPut(cam, body, token) {
  try {
    const response = await apiSalt.put(cam, body, {
      headers: { Authorization: token }
    });
    return response;
  } catch (err) {
    console.log(err);
  }
}

async function ApiDelete(url, body, token) {
  try {
    const response = await apiSalt.delete(url, {
      data: body,
      headers: { Authorization: token }
    });
    return response;
  } catch (err) {
    console.log(err);
  }
}

async function ApiMaster(method, url, body = {}) {
  try {
    const response = await apiSalt[method](url, body);
    return response;
  } catch (err) {
    console.log(err);
  }
}

export {
  ApiGet,
  ApiGetConsulta,
  ApiPost,
  ApiPostLogin,
  ApiPut,
  ApiMaster,
  ApiPostNew,
  ApiDelete
};
