import {
  handleFirstPage,
  handleLastPage,
  handlePageClick,
  handleRowClick
} from '../../functions';

const BlcTitle = (props) => {
  return (
    <th scope="col">
      <div className="d-flex justify-content-between align-items-center">
        <span style={{ fontSize: props.tamSubTexto }}>{props.title}</span>
        <div className="ms-1 text-nowrap">
          <span
            className="me-1"
            onClick={() => props.handleSort(props.order, 'asc')}
          >
            <i className="fa-solid fa-arrow-up"></i>
          </span>
          <span onClick={() => props.handleSort(props.order, 'desc')}>
            <i className="fa-solid fa-arrow-down"></i>
          </span>
        </div>
      </div>
    </th>
  );
};

export const GridPerguntas = (props) => {
  const campos = [
    { title: 'Cód. da Pergunta', order: 'id' },
    { title: 'Descrição da Pergunta', order: 'descricao' },
    { title: 'Abreviação', order: 'abreviacao' }
  ];

  return (
    <table
      className="table table-striped table-hover table-bordered table-sm align-middle"
      style={{ height: '100%' }}
    >
      <thead>
        <tr className="table-secondary">
          {campos.map((campo) => (
            <BlcTitle
              key={campo.order}
              title={campo.title}
              order={campo.order}
              handleSort={props.handleSort}
              tamTexto={props.tamSubTexto}
            />
          ))}
          <th scope="col" style={{ fontSize: props.tamTexto, width: '80px' }}>
            Adicionar
          </th>
        </tr>
      </thead>
      <tbody>
        {props.filteredData?.map((item, index) => (
          <tr
            key={index}
            className={props.selectedRow === index ? 'bg-info' : ''}
            onClick={() => handleRowClick(index, props.setSelectedRow)}
          >
            <th scope="row" style={{ fontSize: props.tamSubTexto }}>
              {item.id}
            </th>
            <td style={{ fontSize: props.tamSubTexto }}>{item.descricao}</td>
            <td style={{ fontSize: props.tamSubTexto }}>{item.abreviacao}</td>
            <td>
              <div>
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={props.data.perguntas?.includes(item.id)}
                  onChange={() => {
                    const updatedPerguntas = props.data.perguntas?.includes(
                      item.id
                    )
                      ? props.data.perguntas.filter((id) => id !== item.id)
                      : [...(props.data.perguntas || ''), item.id];

                    props.setData({
                      ...props.data,
                      perguntas: updatedPerguntas
                    });
                  }}
                />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="4" className="align-middle">
            <nav className="d-flex align-items-center justify-content-center">
              <ul className="pagination">
                <li className="page-item">
                  <p
                    className="page-link"
                    aria-label="PreviousPage"
                    onClick={() =>
                      handleFirstPage(
                        props.setCurrentPage,
                        props.setSelectedRow
                      )
                    }
                  >
                    <span
                      aria-hidden="true"
                      style={{ fontSize: props.tamTexto }}
                    >
                      &laquo;
                    </span>
                  </p>
                </li>
                <li className="page-item">
                  <p
                    className="page-link"
                    aria-label="PreviousIten"
                    onClick={() =>
                      handlePageClick(
                        props.currentPage - 1,
                        props.pageCount,
                        props.setCurrentPage,
                        props.setSelectedRow
                      )
                    }
                  >
                    <span
                      aria-hidden="true"
                      style={{ fontSize: props.tamTexto }}
                    >
                      &lt;
                    </span>
                  </p>
                </li>
                {props.displayedPageNumbers.map((pageNumber) => (
                  <li
                    key={pageNumber}
                    className={`page-item ${
                      pageNumber === props.currentPage ? 'active' : ''
                    }`}
                    style={{ fontSize: props.tamTexto }}
                  >
                    <button
                      className="page-link"
                      onClick={() =>
                        handlePageClick(
                          pageNumber,
                          props.pageCount,
                          props.setCurrentPage,
                          props.setSelectedRow
                        )
                      }
                    >
                      {pageNumber + 1}
                    </button>
                  </li>
                ))}
                <li className="page-item">
                  <p
                    className="page-link"
                    aria-label="NextIten"
                    onClick={() =>
                      handlePageClick(
                        props.currentPage + 1,
                        props.pageCount,
                        props.setCurrentPage,
                        props.setSelectedRow
                      )
                    }
                  >
                    <span
                      aria-hidden="true"
                      style={{ fontSize: props.tamTexto }}
                    >
                      &gt;
                    </span>
                  </p>
                </li>
                <li className="page-item">
                  <p
                    className="page-link"
                    aria-label="NextPage"
                    onClick={() =>
                      handleLastPage(
                        props.setCurrentPage,
                        props.setSelectedRow,
                        props.pageCount
                      )
                    }
                  >
                    <span
                      aria-hidden="true"
                      style={{ fontSize: props.tamTexto }}
                    >
                      &raquo;
                    </span>
                  </p>
                </li>
              </ul>
              <div className="d-flex flex-column">
                <p
                  className="text-muted ms-2"
                  style={{ fontSize: props.tamTexto }}
                >
                  Quantidade de Registros:{' '}
                  {props.dataGrid
                    .filter(
                      (item) =>
                        !props.optionsPerguntaSubarea.some(
                          (option) =>
                            option.codigoArea === props.data.codigoArea &&
                            option.codigoSubarea === props.data.id &&
                            item.id === option.codigoPergunta
                        )
                    )
                    .length.toLocaleString('pt-BR', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    })}
                </p>
              </div>
            </nav>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};
