import { useEffect, useState } from 'react';

import RelAuditoria from '../../../relatorio/Auditoria';
import { ApiGet, ApiGetConsulta, ApiPost } from '../../../services/apiService';

import { format } from 'date-fns';

export const Cadastro = (props) => {
  const [numaut, setNumaut] = useState('');
  const [isInsert, setIsInsert] = useState(false);
  const [currentDate, setCurrentDate] = useState(
    format(new Date(), 'yyyy-MM-dd')
  );
  const [pergunta, setPergunta] = useState([]);
  const [isLoadingNumaut, setIsLoadingNumaut] = useState(false);
  const [isLoadingConfirmar, setIsLoadingConfirmar] = useState(false);
  const [isLoadingEditar, setIsLoadingEditar] = useState(false);

  const newAuditoria = async () => {
    setIsLoadingNumaut(true);
    setIsInsert(true);

    try {
      const response = await ApiGet(
        `/auditoria/numaut/`,
        {
          empresa: localStorage.getItem('sessionEmpresa'),
          loja: localStorage.getItem('sessionLoja')
        },
        localStorage.getItem('sessionToken')
      );
      if (response?.data?.mensagem?.msg.slice(0, 3) !== '100') {
        alert(response?.data?.mensagem?.msg);
        return;
      }

      props.setTela(2);
      setNumaut(response.data.auditoria);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingNumaut(false);
    }
  };

  const newAuditoria_2 = async () => {
    setIsLoadingConfirmar(true);
    const banco = {
      id: props.id, //props.formData[0].qpe_seqque,
      // codpes: props.formData[0].qpe_codpes,
      codpes: localStorage.getItem('sessionCodigo'),
      numaut: numaut
    };

    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const hora = `${hours}:${minutes}:${seconds}`;

    try {
      const body = {
        qpe_seqqpe: numaut,
        qpe_seqque: props.id, // props.formData[0].qpe_seqque,
        qpe_datapl: currentDate,
        // qpe_codpes: props.formData[0].qpe_codpes,
        qpe_codpes: localStorage.getItem('sessionCodigo'),
        qpe_codemp: localStorage.getItem('sessionEmpresa'),
        qpe_codusu: localStorage.getItem('sessionUsuario').toUpperCase(),
        qpe_codloj: localStorage.getItem('sessionLoja'),
        qpe_coduad: localStorage.getItem('sessionAdm'),
        qpe_datincl: currentDate,
        qpe_datatz: currentDate,
        qpe_codpesres: localStorage.getItem('sessionCodigo'),
        qpe_horincl: hora,
        qpe_codpespro: localStorage.getItem('sessionCodigo'),
        qpe_tipger: '',
        qpe_horatz: hora,
        qpe_numger: ''
      };
      const response = await ApiPost(
        `/auditoria/insertquestionario`,
        body,
        localStorage.getItem('sessionToken')
      );

      props.setCad(2);
    } catch (err) {
      console.log(err);
    }

    try {
      const response = await ApiGetConsulta(
        `/auditoria/perguntas/${banco.id}/${banco.codpes}/${banco.numaut}`,
        localStorage.getItem('sessionToken')
      );
      props.setRespostas([]);
      props.setDataInsert(response.data.auditoria);
      setPergunta(response.data.auditoria);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingConfirmar(false);
    }
  };

  const handleDateChange = (event) => {
    setCurrentDate(event.target.value);
  };

  const handleEdit = async (body) => {
    setIsLoadingEditar(true);
    setIsInsert(false);
    const banco = {
      id: body.seqque,
      codpes: body.codpes,
      numaut: body.seqqpe
    };

    try {
      const response = await ApiGetConsulta(
        `/auditoria/perguntas/${banco.id}/${banco.codpes}/${banco.numaut}`,
        localStorage.getItem('sessionToken')
      );
      setNumaut(body.seqqpe);
      props.setTela(2);
      props.setCad(2);
      props.setDataInsert(response.data.auditoria);
      setPergunta(response.data.auditoria);

      let novoArray = [];
      for (const resposta of response.data.auditoria) {
        let novoValor = '';
        if (resposta.res_descri === 'ACERTOU') {
          novoValor = 'S';
        } else if (resposta.res_descri === 'NÃO ACERTOU') {
          novoValor = 'N';
        } else {
          novoValor = '';
        }
        novoArray.push(novoValor);
      }
      props.setRespostas(novoArray);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingEditar(false);
    }
  };

  const [title, setTitle] = useState({
    empresa: localStorage.getItem('sessionFantasia'),
    loja: localStorage.getItem('sessionLojaNome'),
    undadm: localStorage.getItem('sessionNomeAdm'),
    auditor: localStorage.getItem('sessionNome'),
    ambiente: ''
  });

  useEffect(() => {
    if (localStorage.getItem('sessionToken')) {
      setTitle({
        empresa: localStorage.getItem('sessionFantasia'),
        loja: localStorage.getItem('sessionLojaNome'),
        undadm: localStorage.getItem('sessionNomeAdm'),
        auditor: localStorage.getItem('sessionNome'),
        ambiente: props.ambiente
      });
    }
    // eslint-disable-next-line
  }, [localStorage.getItem('sessionToken')]);

  useEffect(() => {
    setTitle((prevTitle) => ({
      ...prevTitle,
      ambiente: props.ambiente
    }));
  }, [props.ambiente]);

  const callRelatorio = async (cod, nome) => {
    const banco = {
      id: props.formData[0].qpe_seqque,
      cod: cod
    };

    try {
      const response = await ApiGetConsulta(
        `/auditoria/relatorio/${banco.id}/${banco.cod}`,
        localStorage.getItem('sessionToken')
      );
      const perguntas = response.data.auditoria;
      RelAuditoria(perguntas, nome);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="d-flex justify-content-center">
      <div className="w-100">
        <div className="d-flex justify-content-between align-items-center bg-secondary">
          <p
            className="text-white ms-3 mb-0"
            style={{ fontSize: props.tamTexto }}
          >
            Questionários
          </p>
          {props.tela === 1 && (
            <button
              type="button"
              className="btn btn-dark btn-sm me-3 mt-1 mb-1"
              onClick={() => newAuditoria()}
              style={{ fontSize: props.tamTexto }}
              disabled={isLoadingNumaut}
            >
              {isLoadingNumaut ? (
                <i className="fa-solid fa-hourglass-start"></i>
              ) : (
                'Nova Auditoria'
              )}
            </button>
          )}
          {props.tela === 2 && props.cad === 1 && (
            <button
              type="button"
              className="btn btn-dark btn-sm me-3 mt-1 mb-1"
              onClick={() => newAuditoria_2()}
              style={{ fontSize: props.tamTexto }}
              disabled={isLoadingConfirmar}
            >
              {isLoadingConfirmar ? (
                <i className="fa-solid fa-hourglass-start"></i>
              ) : (
                'Confirmar'
              )}
            </button>
          )}
        </div>

        {props.tela === 1 ? (
          <div className="w-100">
            <div className="bg-light">
              <table className="table table-sm table-borderless mb-0">
                <tbody>
                  <tr>
                    <td style={{ width: '20%', fontSize: props.tamTexto }}>
                      Empresa
                    </td>
                    <td style={{ width: '80%', fontSize: props.tamTexto }}>
                      {title.empresa}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: '20%', fontSize: props.tamTexto }}>
                      Loja
                    </td>
                    <td style={{ width: '80%', fontSize: props.tamTexto }}>
                      {title.loja}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: '20%', fontSize: props.tamTexto }}>
                      Und. Adm
                    </td>
                    <td style={{ width: '80%', fontSize: props.tamTexto }}>
                      {title.undadm}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: '20%', fontSize: props.tamTexto }}>
                      Auditor
                    </td>
                    <td style={{ width: '80%', fontSize: props.tamTexto }}>
                      {title.auditor?.toUpperCase()}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: '20%', fontSize: props.tamTexto }}>
                      Ambiente
                    </td>
                    <td style={{ width: '80%', fontSize: props.tamTexto }}>
                      {title.ambiente}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div style={{ overflowX: 'auto' }}>
              <table className="table table-bordered">
                <thead className="bg-black" style={{ color: '#FF7F00' }}>
                  <tr>
                    <th scope="col" style={{ fontSize: props.tamTexto }}>
                      Data
                    </th>
                    <th scope="col" style={{ fontSize: props.tamTexto }}>
                      Auditor
                    </th>
                    <th scope="col" style={{ fontSize: props.tamTexto }}></th>
                    <th scope="col" style={{ fontSize: props.tamTexto }}></th>
                  </tr>
                </thead>
                <tbody>
                  {props.formData.map((data, index) => (
                    <tr key={index}>
                      <td scope="row" style={{ fontSize: props.tamSubTexto }}>
                        {format(new Date(data.data), 'dd/MM/yyyy')}
                      </td>
                      <td style={{ fontSize: props.tamSubTexto }}>
                        {data.nome}
                      </td>
                      <td></td>
                      <td>
                        <div className="d-flex">
                          <button
                            type="button"
                            className="btn btn-outline-dark btn-sm"
                            onClick={() =>
                              callRelatorio(data.qpe_seqqpe, data.nome)
                            }
                            style={{ fontSize: props.tamSubTexto }}
                          >
                            Imprimir
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-dark btn-sm ms-2"
                            onClick={() =>
                              handleEdit({
                                seqqpe: data.qpe_seqqpe,
                                codpes: data.qpe_codpes,
                                seqque: data.qpe_seqque
                              })
                            }
                            style={{ fontSize: props.tamSubTexto }}
                            disabled={isLoadingEditar}
                          >
                            {isLoadingEditar ? (
                              <i className="fa-solid fa-hourglass-start"></i>
                            ) : (
                              'Editar'
                            )}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="bg-light w-100">
            <div className="bg-light">
              <div className="row mb-1 mt-1">
                <div className="col-md-4 row">
                  <div className="col-12">
                    <span className="col-form-label">Código</span>
                  </div>

                  <div className="col-12">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      disabled
                      value={numaut}
                    />
                  </div>
                </div>

                <div className="col-md-4 row">
                  <div className="col-12">
                    <span className="col-form-label">Auditor</span>
                  </div>

                  <div className="col-12">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      disabled
                      value={localStorage.getItem('sessionUsuario')}
                    />
                  </div>
                </div>

                <div className="col-md-4 row">
                  <div className="col-12">
                    <span className="col-form-label">
                      Data da {isInsert ? 'Aplicação' : 'Atualização'}
                    </span>
                  </div>

                  <div className="col-12">
                    <input
                      type="date"
                      className="form-control form-control-sm border border-dark"
                      value={currentDate}
                      onChange={handleDateChange}
                    />
                  </div>
                </div>
              </div>

              <div className="row mb-1 mt-1">
                <div className="col-md-6 row">
                  <div className="col-12">
                    <span className="col-form-label">Empresa</span>
                  </div>

                  <div className="col-12">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      disabled
                      value={title.empresa}
                    />
                  </div>
                </div>

                <div className="col-md-6 row">
                  <div className="col-12">
                    <span className="col-form-label">Ambiente</span>
                  </div>

                  <div className="col-12">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      disabled
                      value={title.ambiente}
                    />
                  </div>
                </div>
              </div>
            </div>
            {props.cad === 2 && (
              <div className="mt-2" style={{ overflowX: 'auto' }}>
                <table className="table table-bordered table-sm">
                  <thead style={{ color: '#FF7F00' }}>
                    <tr>
                      <th scope="col" style={{ fontSize: props.tamTexto }}>
                        Sessão
                      </th>
                      <th scope="col" style={{ fontSize: props.tamTexto }}>
                        Pergunta
                      </th>
                      <th scope="col" style={{ fontSize: props.tamTexto }}>
                        Sim
                      </th>
                      <th scope="col" style={{ fontSize: props.tamTexto }}>
                        Não
                      </th>
                      <th scope="col" style={{ fontSize: props.tamTexto }}>
                        Resposta
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {pergunta.map((data, index) => (
                      <tr key={index}>
                        <th scope="row" style={{ fontSize: props.tamSubTexto }}>
                          {data.sar_abrev}
                        </th>
                        <td style={{ fontSize: props.tamSubTexto }}>
                          {data.per_descri}
                        </td>
                        <td>
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() =>
                                props.handleRespostaClick(index, 'S')
                              }
                              style={{ fontSize: props.tamSubTexto }}
                            >
                              S
                            </button>
                          </div>
                        </td>
                        <td>
                          <div>
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() =>
                                props.handleRespostaClick(index, 'N')
                              }
                              style={{ fontSize: props.tamSubTexto }}
                            >
                              N
                            </button>
                          </div>
                        </td>
                        <td
                          className="align-middle text-center"
                          style={{ fontSize: props.tamSubTexto }}
                        >
                          {props.respostas[index] ||
                            (data.res_descri === 'ACERTOU' && 'S') ||
                            (data.res_descri === 'NÃO ACERTOU' && 'N')}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
