import ReactSelect from 'react-select';

export const FiltroDropdown = ({ listaItems, setValue, title, isMany }) => {
  const handleChange = (event) => {
    if (isMany && Array.isArray(event)) {
      setValue(event.map((item) => item.value));
    } else {
      setValue(event);
    }
  };

  const options = listaItems?.map((item) => {
    const parts = item.item.split('-');
    const value = parts[0].trim();
    const label = item.item;
    return { value, label };
  });

  return (
    <div
      className="d-flex flex-column w-100 p-1 rounded"
      style={{ backgroundColor: '#fff' }}
    >
      <label className="form-label m-0">{title}</label>
      <ReactSelect
        closeMenuOnSelect={true}
        isMulti={isMany}
        options={options}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            padding: 0,
            margin: 0,
            overflowY: 'auto',
            maxHeight: '65px'
          })
        }}
        onChange={handleChange}
      />
    </div>
  );
};
