import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

export const GraficoBarraLinha = ({
  dataForm,
  mesIni,
  anoIni,
  titulo,
  meta = 0
}) => {
  const data = {
    labels: dataForm.map((item) => item.id),
    datasets: [
      {
        label: 'Nota',
        data: dataForm.map((item) => item.value),
        backgroundColor: dataForm.map((item) =>
          item.value < meta ? '#ff4d4d' : '#4d4dff'
        ),
        borderColor: dataForm.map((item) =>
          item.value < meta ? '#b30000' : '#0000b3'
        ),
        borderWidth: 1,
        order: 1
      },
      {
        label: 'Meta',
        data: Array(dataForm.length).fill(meta),
        type: 'line',
        borderColor: '#33cc33', // verde
        backgroundColor: '#33cc33', // verde
        borderWidth: 2,
        fill: false
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels: {
          filter: function (legendItem, chartData) {
            return legendItem.text.startsWith('Meta');
          },
          usePointStyle: true,
          pointStyle: 'circle'
        }
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const datasetLabel = context.dataset.label || '';
            const value =
              typeof context.raw === 'number'
                ? context.raw.toFixed(2)
                : context.raw;
            return `${datasetLabel}: ${value}`;
          }
        },
        titleFont: {
          size: 20
        },
        bodyFont: {
          size: 22
        }
      }
    },
    scales: {
      x: {
        ticks: {
          maxRotation: 45,
          minRotation: 45
        },
        title: {
          display: false,
          text: 'Ambiente'
        }
      },
      y: {
        title: {
          display: true,
          text: 'Nota'
        }
      }
    }
  };

  return (
    <div
      style={{
        height: '90%',
        width: '100%',
        minWidth: '360px',
        minHeight: '400px'
      }}
    >
      <h3 style={{ textAlign: 'center', color: '#000' }}>{titulo}</h3>
      <h4 style={{ textAlign: 'center', color: '#000' }}>
        Período {mesIni}/{anoIni}
      </h4>
      <Bar data={data} options={options} />
    </div>
  );
};
