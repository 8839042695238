import { useState } from 'react';

import { Auditoria } from '../modal/auditoria/Auditoria';
import { Manutencao } from '../modal/manutencao/Manutencao';
import { handleCard } from './handleCard';

const Card = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const cardStyle = {
    backgroundColor: isHovered ? '#cfe5ed' : props.bgMenu,
    color: props.fontMenu
  };

  return (
    <div
      className="card cursor w-100 mt-1 mb-1"
      tabIndex="-1"
      data-bs-toggle="modal"
      data-bs-target={props.modal}
      style={cardStyle}
      onClick={() => props.onClick()}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="card-body d-flex flex-column justify-content-center align-items-center">
        <h4 className="card-title">
          <i
            className={props.icon}
            style={{ fontSize: props.tamSubTitulo }}
          ></i>
        </h4>
        <h5
          className="card-text text-center fw-semibold"
          style={{ fontSize: props.tamSubTitulo }}
        >
          {props.texto}
        </h5>
      </div>
    </div>
  );
};

export function Menu(props) {
  const [isOpen, setIsOpen] = useState('');

  const cards = handleCard(props.dataEmpresa.area);

  const optionsCards = [
    {
      option: (
        <Card
          texto="Auditoria 5S"
          modal="#Auditoria"
          icon="fa-solid fa-magnifying-glass"
          bgMenu={props.bgMenu}
          fontMenu={props.fontMenu}
          tamSubTitulo={props.tamSubTitulo}
        />
      )
    },

    localStorage.getItem('sessionNivelDeAdministracao') === '50' ||
      (localStorage.getItem('sessionNivelDeAdministracao') === '99' && {
        option: (
          <Card
            texto="Manutenção"
            modal="#Manutencao"
            icon="fa-solid fa-magnifying-glass"
            bgMenu={props.bgMenu}
            fontMenu={props.fontMenu}
            tamSubTitulo={props.tamSubTitulo}
          />
        )
      })
  ];

  return (
    <div className="mt-1" style={{ width: '95vw', marginLeft: '2.5vw' }}>
      <div className="row">
        <div className="col-12 col-sm-4 col-md-4 col-lg-4 mb-2 d-flex flex-column align-items-center">
          {cards
            .filter((item) => {
              const values = Object.values(item)[0];
              return values.situacao === 'ATIVO' && values.coluna === '1';
            })
            .map((opt) => {
              if (opt) {
                const values = Object.values(opt)[0];

                return (
                  <Card
                    key={values.chave}
                    texto={values.nome}
                    modal={props.login ? `#${values.chave}` : '#msgError'}
                    icon="fa-solid fa-magnifying-glass"
                    bgMenu={props.bgMenu}
                    fontMenu={props.fontMenu}
                    tamSubTitulo={props.tamSubTitulo}
                    onClick={() => setIsOpen(values.chave)}
                  />
                );
              }
            })}
        </div>
        <div className="col-12 col-sm-4 col-md-4 col-lg-4 mb-2 d-flex flex-column align-items-center">
          {cards
            .filter((item) => {
              const values = Object.values(item)[0];
              return values.situacao === 'ATIVO' && values.coluna === '2';
            })
            .map((opt) => {
              if (opt) {
                const values = Object.values(opt)[0];

                return (
                  <Card
                    key={values.chave}
                    texto={values.nome}
                    modal={props.login ? `#${values.chave}` : '#msgError'}
                    icon="fa-solid fa-magnifying-glass"
                    bgMenu={props.bgMenu}
                    fontMenu={props.fontMenu}
                    tamSubTitulo={props.tamSubTitulo}
                    onClick={() => setIsOpen(values.chave)}
                  />
                );
              }
            })}
        </div>
      </div>

      {props.login ? (
        <>
          <Auditoria
            tamTitulo={props.tamTitulo}
            tamSubTitulo={props.tamSubTitulo}
            tamTexto={props.tamTexto}
            tamSubTexto={props.tamSubTexto}
            dataBanco={props.dataBanco}
          />
          <Manutencao
            tamTitulo={props.tamTitulo}
            tamSubTitulo={props.tamSubTitulo}
            tamTexto={props.tamTexto}
            tamSubTexto={props.tamSubTexto}
            dataBanco={props.dataBanco}
          />
        </>
      ) : (
        <div
          className="modal fade"
          id="msgError"
          tabIndex="-1"
          aria-hidden="true"
          data-bs-backdrop="static"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div
                  className="alert alert-danger alert-dismissible"
                  role="alert"
                >
                  <div>Não Logado</div>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
