export const CadGeral = ({ data, setData, insertOrUpdate, optionsArea }) => {
  return (
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-12 row">
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Cód. da Área</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.codigoArea || ''}
            disabled
          />
        </div>
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Descrição da Área</label>
          <select
            className="form-select form-select-sm border border-dark"
            name="res_tipapa"
            value={data?.codigoArea || ''}
            onChange={(e) => setData({ ...data, codigoArea: e.target.value })}
            disabled={insertOrUpdate === 'read' && true}
          >
            <option defaultValue></option>
            {optionsArea?.map((option, index) => (
              <option key={index} value={option.are_codare}>
                {option.are_descri}
              </option>
            ))}
          </select>
        </div>
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Cód. da Subarea</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.id || ''}
            disabled
          />
        </div>
        <div className="col-sm-12 col-md-8 col-lg-3">
          <label className="form-label">Descrição da Subarea</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.descricao || ''}
            onChange={(e) => setData({ ...data, descricao: e.target.value })}
            disabled={insertOrUpdate === 'read' && true}
          />
        </div>
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Abreviação</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.abreviacao || ''}
            onChange={(e) => setData({ ...data, abreviacao: e.target.value })}
            disabled={insertOrUpdate === 'read' && true}
          />
        </div>
        <div className="col-sm-12 col-md-2 col-lg-1">
          <label className="form-label">Peso</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.peso || ''}
            onChange={(e) => setData({ ...data, peso: e.target.value })}
            disabled={insertOrUpdate === 'read' && true}
          />
        </div>
      </div>

      <div className="col-sm-12 col-md-12 col-lg-12 row">
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Usuário</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.usuario || ''}
            disabled
          />
        </div>
      </div>

      <div className="col-sm-12 col-md-12 col-lg-12 row">
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Dt. Inclusão</label>
          <input
            type="date"
            className="form-control form-control-sm border border-dark"
            value={data?.datincl || ''}
            disabled
          />
        </div>
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Hr. Inclusão</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.horincl || ''}
            disabled
          />
        </div>
      </div>

      <div className="col-sm-12 col-md-12 col-lg-12 row">
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Dt. Atualização</label>
          <input
            type="date"
            className="form-control form-control-sm border border-dark"
            value={data?.datatz || ''}
            disabled
          />
        </div>
        <div className="col-sm-12 col-md-2 col-lg-2">
          <label className="form-label">Hr. Atualização</label>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={data?.horatz || ''}
            disabled
          />
        </div>
      </div>
    </div>
  );
};
