export const getDisplayedPageNumbers = (currentPage, pageCount) => {
  const delta = 2;
  const minPage = Math.max(currentPage - delta, 0);
  const maxPage = Math.min(currentPage + delta, pageCount - 1);
  const pageNumbers = [];
  for (let i = minPage; i <= maxPage; i++) {
    pageNumbers.push(i);
  }
  return pageNumbers;
};

export const handleRowClick = (index, setSelectedRow) => {
  setSelectedRow(index);
};

export const handlePageClick = (
  pageNumber,
  pageCount,
  setCurrentPage,
  setSelectedRow
) => {
  if (pageNumber < 0 || pageNumber >= pageCount) {
    return;
  }
  setCurrentPage(pageNumber);
  setSelectedRow(null);
};

export const handleFirstPage = (setCurrentPage, setSelectedRow) => {
  setCurrentPage(0);
  setSelectedRow(null);
};

export const handleLastPage = (setCurrentPage, setSelectedRow, pageCount) => {
  setCurrentPage(pageCount - 1);
  setSelectedRow(null);
};
